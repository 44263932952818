import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import { Buffer } from "buffer";
global.Buffer = Buffer;

/**
 * Initialize AWS Amplify.
 */

// Load environment variables
const AMPLIFY_AWS_PROJECT_REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const AMPLIFY_AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
const AMPLIFY_AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
const AMPLIFY_AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID;
const AMPLIFY_AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
const AMPLIFY_AWS_OAUTH_DOMAIN = process.env.REACT_APP_AWS_OAUTH_DOMAIN;
const AMPLIFY_REDIRECT_SIGNIN = process.env.REACT_APP_REDIRECT_SIGNIN;
const AMPLIFY_REDIRECT_SIGNOUT = process.env.REACT_APP_REDIRECT_SIGNOUT;

// Configure AWS Amplify Backend
try {
  // Set oath configuration by assigning proper redirect URLs
  const updatedAwsConfig = {
    // ...awsExports,
    aws_project_region: AMPLIFY_AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: AMPLIFY_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: AMPLIFY_AWS_COGNITO_REGION,
    aws_user_pools_id: AMPLIFY_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: AMPLIFY_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      // ...awsExports.oauth,
      domain: AMPLIFY_AWS_OAUTH_DOMAIN,
      scope: ["openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: AMPLIFY_REDIRECT_SIGNIN,
      redirectSignOut: AMPLIFY_REDIRECT_SIGNOUT,
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };

  console.log(updatedAwsConfig);
  Amplify.configure(updatedAwsConfig);
} catch (err) {
  console.log("[_app.js] Error setting Amplify configuration.", err);
}

/**
 * Set up ReactDOM and instantiate App.
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // TODO VISH REMOVED, REMEMBER TO PUT BACK
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
