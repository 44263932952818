import type { Codec } from "numcodecs";

type Config = Record<string, any>;
type CodecImporter = () => Promise<{ fromConfig: (config: Config) => Codec }>;

export const registry: Map<string, CodecImporter> = new Map();

registry.set(...default_import("blosc"));
registry.set(...default_import("zstd"));
registry.set(...default_import("gzip"));
registry.set(...default_import("zlib"));
registry.set(...default_import("lz4"));
registry.set(...default_import("wkb"));

function default_import(
	id: string,
	version = "0.2.2",
) {
	let link: string;
	if (typeof process === "undefined") {
		// browser

		// Note: Edit made here to import from custom numcodecs 
		// instead of numcodecs at url which is missing custom codecs. 
		link = `https://cdn.skypack.dev/numcodecs@${version}/${id}`;
		// link = `../numcodecs/${id}.mjs`; 
	} else {
		// node
		link = `numcodecs/${id}`;
	}

	const out = [
		id,
		(() => import(/* @vite-ignore */ link).then((m) => m.default)) as CodecImporter,
	] as const;

	// console.log("zarrita: ", { out });
	return out; 
}
