import { Store } from "./store";
/**
 * More cleanly merge with other parts of code
 * after set implementation
 */
export class GeoJSON extends Store {
  constructor(
    root_url,
    type = ELEN_FILETYPES.geojson,
    url_sign = url_sign,
    cache_options = { max: 200 }
  ) {
    super(root_url, type, url_sign, cache_options);
  }

  async set(data, id, flush = true) {
    let cached = {};
    if (this._cache.has(key)) {
      cached = this._cache.get(key);
      cached.dirty = true;
      cached.data = chunk_data;
    } else {
      cached = { data: data, key: key, coords: coords, dirty: true };
    }

    this._cache.set(key, cached);

    if (flush) {
      await this.flush_chunk(coords, chunk_data);
    }
  }

  async set(data) {
    this.write(this.get_uri("", data));
  }
}
