import union from "turf/src/union/index.js";
import intersect from "@turf/intersect";
import * as helper from "turf/src/helpers/index.js";
import difference from "turf/src/difference/index.js";
import booleanOverlap from "turf/src/boolean-overlap/index.js";
import pointOnFeature from "turf/src/point-on-feature/index.js";
import simplify from "turf/src/simplify/index.js";

let turf = {
  union,
  intersect,
  difference,
  booleanOverlap,
  polygon: helper.polygon,
  pointOnFeature,
  simplify,
};

export default turf;
