/**
 * Modification of L.Draw Rectangle from
 * https://stackoverflow.com/questions/23922832/leaflet-location-filter-example-and-draggable-resizeable-rectangular-area-select
 *
 * Additionally modified to remove center move marker.
 * Assumes Rectangle is in-place.
 */
import "./Edit.SimpleShape";

L.Edit = L.Edit || {};

L.Edit.Rectangle = L.Edit.SimpleShape.extend({
  _createResizeMarker: function () {
    var corners = this._getCorners();

    this._resizeMarkers = [];

    for (var i = 0, l = corners.length; i < l; i++) {
      this._resizeMarkers.push(
        this._createMarker(corners[i], this.options.resizeIcon)
      );
      // Monkey in the corner index as we will need to know this for dragging
      this._resizeMarkers[i]._cornerIndex = i;
    }
  },

  _onMarkerDragStart: function (e) {
    L.Edit.SimpleShape.prototype._onMarkerDragStart.call(this, e);

    // Save a reference to the opposite point
    var corners = this._getCorners(),
      marker = e.target,
      currentCornerIndex = marker._cornerIndex;

    this._oppositeCorner = corners[(currentCornerIndex + 2) % 4];

    this._toggleCornerMarkers(0, currentCornerIndex);
  },

  _onMarkerDragEnd: function (e) {
    this._toggleCornerMarkers(1);

    this._repositionCornerMarkers();

    L.Edit.SimpleShape.prototype._onMarkerDragEnd.call(this, e);
  },

  _resize: function (latlng) {
    // Update the shape based on the current position of this corner and the opposite point
    this._shape.setBounds(L.latLngBounds(latlng, this._oppositeCorner));
  },

  _setSize: function (bounds) {
    this._shape.setBounds(bounds);
    this._repositionCornerMarkers();
    this._fireEdit();
  },

  _getCorners: function () {
    var bounds = this._shape.getBounds(),
      nw = bounds.getNorthWest(),
      ne = bounds.getNorthEast(),
      se = bounds.getSouthEast(),
      sw = bounds.getSouthWest();

    return [nw, ne, se, sw];
  },

  _toggleCornerMarkers: function (opacity) {
    for (var i = 0, l = this._resizeMarkers.length; i < l; i++) {
      this._resizeMarkers[i].setOpacity(opacity);
    }
  },

  _repositionCornerMarkers: function () {
    var corners = this._getCorners();

    for (var i = 0, l = this._resizeMarkers.length; i < l; i++) {
      this._resizeMarkers[i].setLatLng(corners[i]);
    }
  },
});

L.Rectangle.addInitHook(function () {
  if (L.Edit.Rectangle) {
    this.editing = new L.Edit.Rectangle(this);

    if (this.options.editable) {
      this.editing.enable();
    }
  }
});
