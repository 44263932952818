const BASE_GRID = "micron";

export class Grid {
  /**
   * @param {Object | Int} tile_size
   * @param {Int} [tile_size.x]
   * @param {Int} [tile_size.y]
   * @param {Object} origin
   * @param {Int} origin.i
   * @param {Int} origin.j (y) origin index
   * @param {String} origin.grid grid that origin is used for
   */
  constructor(
    mpp,
    tile_size,
    origin = { x: 0, y: 0, grid: BASE_GRID },
    bounds = null
  ) {
    this.mpp = mpp;
    this.tile_size = tile_size;
    this.origin = origin;
    this.bounds = bounds;
  }

  /**
   * Outputs coordinate in another grid.
   */
  convert(coord, to_grid) {
    const { x, y } = coord;
    return {
      x: (x * this.mpp) / to_grid.mpp - this.coord(to_grid.origin.x),
      y: (y * this.mpp) / to_grid.mpp - this.coord(to_grid.origin.y),
    };
  }

  /**
   * Outputs closest index of a coordinate
   * in this grid.
   */
  index(coord) {
    const { x, y } = coord;
    if (typeof this.tile_size === "object") {
      return {
        i: Math.floor(x / this.tile_size.x),
        j: Math.floor(y / this.tile_size.y),
      };
    } else {
      return {
        i: Math.floor(x / this.tile_size),
        j: Math.floor(y / this.tile_size),
      };
    }
  }

  coord(index) {
    const { i, j } = index;
    if (typeof this.tile_size === "object") {
      return {
        x: i * this.tile_size.x,
        y: j * this.tile_size.y,
      };
    } else {
      return {
        x: i * this.tile_size,
        y: j * this.tile_size,
      };
    }
  }
}

export class GridManager {
  constructor() {
    this._grids = {};
    this.initialize();
  }

  initialize() {
    this.add_grid(BASE_GRID, 1, 512); // Base Grid
  }

  add_grid(id, mpp, tile_size, origin, bounds) {
    this._grids[id] = new Grid(mpp, tile_size, origin, bounds);
  }

  get_index(grid_id, coord, in_grid = null) {
    const grid = this._grids[grid_id];
    let index;

    if (in_grid !== null) {
      const to_grid = this._grids[in_grid];
      index = to_grid.index(grid.convert(coord, to_grid));
    } else {
      index = grid.index(x, y);
    }

    return index;
  }
}
