import { elen } from ".";

export class TileBuffer {
  constructor(control) {
    // Parent control (L.annotate)
    this._control = control;
    console.log("[TileBuffer] control: ", control);
    // State vars
    this.features = { normal: {}, selected: {} };
    this.layers = { normal: null, selected: null };
    this.tile = {
      html: null,
      coords: {},
      id: null,
    };
  }

  is_empty() {
    let empty = true;
    for (let k of Object.keys(this.features)) {
      if (Object.keys(this.features[k]).length > 0) {
        empty = false;
        break;
      }
    }
    return empty;
  }

  /**
   * Load chunk into buffer.
   *
   * NOTE: Ensure prior contents of buffer have been
   * cached to store. This function will overwrite
   * all state variables of TileBuffer to a clean state.
   */
  load(features, tile) {
    this.tile = tile;
    // Reset state variables
    this.features = { normal: {}, selected: {} };
    this.layers = { normal: null, selected: null };
    // Load feature normal
    this.features.normal = JSON.parse(JSON.stringify(features));
    const featuresArray = Object.values(features).map((feature) => {
      return {
        ...feature.properties.bbox, // spatial coords
        feature,
      };
    });
    this.refresh();
  }

  /**
   * @param {Store} store caches current contents of tileBuffer to store
   *
   * Note: In the case of an untiled, `this.features.normal`
   * will consist of all features in the entire slide, which
   * in this case is the `chunk`. In GeoJSON case, it's just
   * a chunk with an empty string key.
   */
  async save_to(store) {
    const data = Object.fromEntries(
      Object.entries(this.features.normal).map(([key, val]) => {
        delete val.properties["state"];
        return [key, val];
      })
    );

    console.log("Saving chunk: ", data);

    let key =
      this.tile.coords?.x && this.tile.coords?.y
        ? [this.tile.coords.x, this.tile.coords.y, 0].join(".")
        : "";
    await store.set(data, key);
  }

  /**
   * Add single new feature. Defaults
   * to "selected" state on add.
   */

  add(feature) {
    this.shift_all("selected", "normal");
    this.features.selected[feature.properties.id] = feature;

    if (this._control.callbacks?.update_ontology_control) {
      this._control.callbacks.update_ontology_control(feature.properties.type);
    }
    this.refresh();
  }

  /**
   * Move feature from one type to another.
   */
  shift(feature, tostate) {
    if (!feature) {
      return;
    }
    const fromstate = feature.properties.state || "normal";
    delete this.features[fromstate][feature.properties.id];
    feature.properties.state = tostate;
    this.features[tostate][feature.properties.id] = feature;
  }

  /**
   * Shift all features from one state to another.
   */
  shift_all(fromstate, tostate) {
    Object.values(this.features[fromstate]).forEach((feature) => {
      feature.properties.state = tostate;
    });
    Object.assign(this.features[tostate], this.features[fromstate]);
    this.features[fromstate] = {};
  }

  // Deletes all features for given state.
  clear(state) {
    this.features[state] = {};
    this.refresh([state]);
  }

  /**
   * Refresh GeoJSON layers with shifted features
   */
  refresh(states_to_update = null) {
    if (!this._control._map) {
      return;
    }

    // Get which states should be updated
    states_to_update =
      states_to_update !== null ? states_to_update : Object.keys(this.layers);
    // Remove all previous layers
    for (let state of states_to_update) {
      if (this.layers[state]) {
        this.layers[state].remove();
      }
    }

    const options = {
      coordsToLatLng: function (coords) {
        // FIXME: May have to revert, return coords
        try {
          return new L.LatLng(coords[1], coords[0]);
        } catch (err) {
          return coords; // For geojson case
        }
      },
      onEachFeature: (feature, layer) => {
        if (
          this._control.options.ontology.options[feature.properties.type]
            .visible
        ) {
          // Set control click if feature type is visible
          layer.on({
            click: this._control.click_feature.bind(this._control),
          });
        }
      },
      pointToLayer: function (feature, latlng) {
        var geojsonMarkerOptions = {
          radius: 6,
          fillColor:
            this._control.options.ontology.options[feature.properties.type][
              "color"
            ],
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.2,
        };
        return L.circleMarker(latlng, geojsonMarkerOptions);
      }.bind(this),
    };
    const styles = {
      normal: (feature) => {
        return {
          weight: feature.properties.type === "roi" ? 3 : 1.5,
          color:
            this._control.options.ontology.options[feature.properties.type][
              "color"
            ],
          zIndex: feature.properties.type === "roi" ? 100 : 10,
        };
      },
      selected: (feature) => {
        return {
          weight: 1.5,
          color: "#fff200",
          zIndex: 10,
        };
      },
    };
    // Update each layer and add to map
    for (let state of states_to_update) {
      let layer = L.geoJSON(
        elen.feature_collection(
          Object.values(this.features[state]).filter((feature) => {
            return (
              this._control.options.ontology.options[feature.properties.type]
                ?.visible ?? false
            );
          })
        ),
        {
          style: styles[state],
          ...options,
        }
      );
      layer.addTo(this._control._map);
      this.layers[state] = layer;
    }
  }

  /**
   * Remove all layers in buffer.
   */
  remove() {
    for (let k of Object.keys(this.features)) {
      if (this.layers[k] !== null) {
        this.layers[k].remove();
      }

      this.layers[k] = null;
    }
  }
}
