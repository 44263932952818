(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bopen/leaflet-area-selection/dist/index.css"));
	else if(typeof define === 'function' && define.amd)
		define(["@bopen/leaflet-area-selection/dist/index.css"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bopen/leaflet-area-selection/dist/index.css")) : factory(root["@bopen/leaflet-area-selection/dist/index.css"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__bopen_leaflet_area_selection_dist_index_css__) => {
return 