import { LRUCache } from "lru-cache";

function array_to_obj(array) {
  const result = array.reduce((acc, obj, index) => {
    if (!("id" in obj.properties)) {
      obj.properties.id = index;
    }
    acc[obj.properties.id] = obj;
    return acc;
  }, {});
  return result;
}
const FILETYPES = Object.freeze({
  geojson: "geojson",
  geojson_gz: "geojson.gz",
  zarr: "zarr",
});
const FS_ACTIONS = Object.freeze({
  READ: "getObject",
  WRITE: "putObject",
});

/**
 * Base class for caching and file I/O.
 */
export class Store {
  constructor(
    root_url,
    url_sign = url_sign,
    type = FILETYPES.geojson,
    cache_options = { max: 200 }
  ) {
    this.root = root_url;
    this.type = type;
    this.url_sign = url_sign;

    this.url_map = Object.fromEntries(
      Object.values(FS_ACTIONS).map((k) => [k, {}])
    );

    this._cache = new LRUCache({
      ...cache_options,
      dispose: async (cached, _) => {
        if (cached.dirty) {
          await this.write(cached.data, cached.key);
        }
      },
    });
  }

  /**
   * Returns true if root/key of store is a file
   * returns false if root/key is a directory.
   */
  is_file(key = "") {
    return /\.[0-9a-z]+$/i.test([this.root, key].join(""));
  }

  async get_uri(key = "", action = FS_ACTIONS.READ, contentType = undefined) {
    let raw_uri = [this.root, key].join("");

    if (!this.is_file()) {
      // If root is NOT already a filename
      switch (this.type) {
        case FILETYPES.geojson:
        case FILETYPES.geojson_gz: {
          raw_uri = [raw_uri, ".", this.type].join("");
          break;
        }
        default: {
          break;
        }
      }
    }

    let url = undefined;

    if (raw_uri in this.url_map[action]) {
      url = this.url_map[action][raw_uri];
    } else if (this.url_sign) {
      try {
        url = await this.url_sign(raw_uri, action, contentType);
        this.url_map[action][raw_uri] = url;
      } catch (err) {
        console.error(
          "[store:Store:get_chunk] Error using `this.url_map()` to map url. ",
          err
        );
        console.trace();
      }
    } else {
      url = raw_uri;
      this.url_map[action][raw_uri] = url;
    }

    return url;
  }

  async read(key = "") {
    // Check the cache for data first
    if (this._cache.has(key)) {
      console.log("[READ FROM CACHE]", key);
      return this._cache.get(key).data;
    }

    // Get the tile from url endpoint
    let url = await this.get_uri(key, FS_ACTIONS.READ);
    console.log("[READING]", {
      key,
      url,
    });

    let data, response;
    try {
      response = await fetch(url);
      if (!response.ok) {
        throw new Error("Chunk was not found or issue parsing to JSON.");
      }
    } catch (err) {
      console.warn(`[store.js:Store.read] Error fetching chunk =${key}`, err);
      return {};
    }

    switch (this.type) {
      case FILETYPES.geojson: {
        const raw_data = await response.json(); // JSON Response
        data = array_to_obj(raw_data.features);
        break;
      }
      case FILETYPES.geojson_gz: {
        const decoded_stream = (await response.blob())
          .stream()
          .pipeThrough(new DecompressionStream("gzip"));
        let raw_data;
        try {
          raw_data = JSON.parse(await new Response(decoded_stream).text());
        } catch (err) {
          console.log("Failed parse with err", err);
          console.trace();
        }
        data = array_to_obj(raw_data.features);
        break;
      }
      default:
        break;
    }
    this._cache.set(key, {
      key: key,
      data: data,
      dirty: false,
    });

    return data;
  }

  /**
   * Sets data value in cache and conditionally
   * writes.
   */
  async set(data, key = "", write = true) {
    let cached = {};
    if (this._cache.has(key)) {
      cached = this._cache.get(key);
      cached.dirty = true;
      cached.data = data;
    } else {
      cached = { data: data, key: key, dirty: true };
    }

    this._cache.set(key, cached);

    if (write) {
      await this.write(data, key);
    }
  }

  async write(data, key = "") {
    console.log("FLUSHING", key);
    let request;

    switch (this.type) {
      case FILETYPES.geojson: {
        request = {
          body: JSON.stringify({
            features: Object.values(data),
            type: "FeatureCollection",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        };
        break;
      }
      case FILETYPES.geojson_gz: {
        const str_data = JSON.stringify({
          features: Object.values(data),
          type: "FeatureCollection",
        });
        const stream = new ReadableStream({
          start(controller) {
            controller.enqueue(new TextEncoder().encode(str_data));
            controller.close();
          },
        });
        const encoded_data = await (
          await new Response(
            stream.pipeThrough(new CompressionStream("gzip"))
          ).blob()
        ).arrayBuffer();

        request = {
          body: encoded_data,
          headers: {
            "Content-Type": "application/octet-stream",
          },
        };
        break;
      }
      case FILETYPES.zarr: {
        console.error("File of type `zarr` does not have write capability.");
        return;
      }
      default:
        break;
    }

    let url = await this.get_uri(
      key,
      FS_ACTIONS.WRITE,
      request.headers["Content-Type"]
    );

    try {
      const response = await fetch(url, {
        method: "PUT",
        body: request.body,
        headers: {
          ...request.headers,
        },
      });
      if (!response.ok) {
        throw new Error("Issues in flushing");
      }
    } catch (err) {
      console.warn(
        `[store:Store.get_chunk] Error flushing chunk with key=${key}`,
        err
      );
    }
    console.log("[FLUSH]", key);
  }
}
