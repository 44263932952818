/**
 * L.textbox
 *
 * Used to add textboxes to leaflet map.
 */
const TextBox = L.Control.extend({
  initialize: function (text) {
    this._text = text;
  },

  onAdd: function (map) {
    this._div = L.DomUtil.create("div", "annotate-state");
    this._div.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    this._div.style.color = "white";
    this._div.style.padding = "8px";
    this.update(this._text);
    return this._div;
  },

  update: function (text) {
    this._text = text;
    this._div.innerHTML = text;
  },

  onRemove: function (map) {
    // Remove the control from the map when not needed
    this._div.remove();
  },
});

L.Control.TextBox = TextBox;
L.control.textBox = function (options) {
  return new TextBox(options);
};

export { TextBox };
