L.Edit = L.Edit || {};

L.Edit.SimpleShape = L.Handler.extend({
  options: {
    resizeIcon: new L.DivIcon({
      iconSize: new L.Point(16, 16),
      className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-resize",
    }),
  },

  initialize: function (shape, options) {
    this._shape = shape;
    L.Util.setOptions(this, options);
  },

  addHooks: function () {
    if (this._shape._map) {
      this._map = this._shape._map;

      if (!this._markerGroup) {
        this._initMarkers();
      }
      this._map.addLayer(this._markerGroup);
    }
  },

  removeHooks: function () {
    if (this._shape._map) {
      for (var i = 0, l = this._resizeMarkers.length; i < l; i++) {
        this._unbindMarker(this._resizeMarkers[i]);
      }
      this._resizeMarkers = null;

      this._map.removeLayer(this._markerGroup);
      delete this._markerGroup;
    }

    this._map = null;
  },

  updateMarkers: function () {
    this._markerGroup.clearLayers();
    this._initMarkers();
  },

  _initMarkers: function () {
    if (!this._markerGroup) {
      this._markerGroup = new L.LayerGroup();
    }

    // Create edge marker
    this._createResizeMarker();
  },

  _createResizeMarker: function () {
    // Children override
  },

  _createMarker: function (latlng, icon) {
    var marker = new L.Marker(latlng, {
      draggable: true,
      icon: icon,
      zIndexOffset: 10,
    });

    this._bindMarker(marker);

    this._markerGroup.addLayer(marker);

    return marker;
  },

  _bindMarker: function (marker) {
    marker
      .on("dragstart", this._onMarkerDragStart, this)
      .on("drag", this._onMarkerDrag, this)
      .on("dragend", this._onMarkerDragEnd, this);
  },

  _unbindMarker: function (marker) {
    marker
      .off("dragstart", this._onMarkerDragStart, this)
      .off("drag", this._onMarkerDrag, this)
      .off("dragend", this._onMarkerDragEnd, this);
  },

  _onMarkerDragStart: function (e) {
    var marker = e.target;
    marker.setOpacity(0);

    this._shape.fire("editstart");
  },

  _fireEdit: function () {
    this._shape.edited = true;
    this._shape.fire("edit");
  },

  _onMarkerDrag: function (e) {
    var marker = e.target,
      latlng = marker.getLatLng();

    this._resize(latlng);

    this._shape.redraw();
    this._fireEdit();
  },

  _onMarkerDragEnd: function (e) {
    var marker = e.target;
    marker.setOpacity(1);

    this._fireEdit();
  },
  _resize: function () {
    // Children override
  },
});
