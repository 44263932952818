import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const LabelImages = ({ labelImages }) => {
  return (
    <>
      {labelImages !== null ? (
        labelImages.length === 0 ? (
          <Typography sx={{ marginRight: 14 }}>No label images</Typography>
        ) : (
          <Gallery options={{ initialZoomLevel: "fit" }}>
            {labelImages.map(({ raster, imageSrc: image }, i) => {
              return (
                <Item
                  key={i}
                  id={i}
                  original={image}
                  width={raster.width}
                  height={raster.height}
                >
                  {({ ref, open }) => (
                    <img
                      style={{
                        width: 100,
                        height: 100,
                        marginLeft: 4,
                        marginRight: 8,
                        padding: 4,
                      }}
                      ref={ref}
                      onClick={open}
                      src={image}
                      alt="Label"
                    />
                  )}
                </Item>
              );
            })}
          </Gallery>
        )
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default LabelImages;
