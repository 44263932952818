import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Box,
  Collapse,
  IconButton,
  Typography,
  ListItemText,
} from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import Divider from "@mui/material/Divider";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  FINDER_WIDTH,
  SIGN_OUT_HEIGHT,
  LAYER_PROPORTION,
  USABLE_FILE_TYPES,
} from "../constants";

import { AlertContext } from "./AlertProvider";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getHostCwd, ls, getUrl } from "../utils/utils";

const HEIGHT = 600;

const Finder = ({ layers, params }) => {
  const hostCwd = getHostCwd(params?.indexDir);
  const [host, setHost] = useState(hostCwd[0]);
  const [cwd, setCwd] = useState(hostCwd[1]);
  const [dirContents, setDirContents] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ls(`${host}${cwd}`).then((contents) => {
      setDirContents(contents);
    });
  }, [cwd]);

  const onBack = () => {
    setCwd((prevCwd) => {
      let parts = prevCwd.split("/");
      parts.splice(-2, 1);
      let newCwd = parts.join("/");
      return newCwd;
    });
  };

  const handleFileOnClick = async (file) => {
    try {
      if (!USABLE_FILE_TYPES.includes(file.ext)) return undefined;
      const possibleSlideLayer = layers.find((layer) => layer.ext === "tif");
      const fileUriReq = `uri=${host}${cwd}${file.name}`;
      const indexDirReq = params?.indexDir
        ? `&indexDir=${params.indexDir}`
        : "";
      if (layers.length === 0 || (possibleSlideLayer && file.ext === "tif")) {
        navigate(`?${fileUriReq}${indexDirReq}`);
      } else {
        navigate(`${location.search}&${fileUriReq}`);
      }
    } catch (err) {
      console.log("[Finder] Error accessing file", err);
    }
  };

  const handleLayerOnClick = async (layer) => {
    try {
      layers = layers.filter((item) => item != layer);
      console.log({ layers, layer });
      navigate(`?${getUrl(layers, params)}`);
    } catch (err) {
      console.log("[ListLayers] Error accessing file", err);
    }
  };

  return (
    <Box
      sx={{
        width: 250,
        height: HEIGHT,
      }}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        sx={{ width: "100%", pl: 2, pr: 2, overflow: "hidden" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 500, ml: 0.5, pb: 0.5 }}>
            {host}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {cwd !== "/" ? (
              <IconButton onClick={onBack} size={"small"}>
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <Typography
              sx={{
                fontSize: 12,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {cwd}
            </Typography>
          </Box>

          {dirContents.length > 0 ? (
            <List
              sx={{
                overscrollBehavior: "contain",
                overflow: "scroll",
                maxHeight: HEIGHT - 30,
              }}
            >
              <TransitionGroup>
                {dirContents.map((file) => {
                  return (
                    <Collapse key={file.name}>
                      {file.isFolder ? (
                        <Paper sx={{ ml: 1, mr: 1, mb: 1 }}>
                          <ListItem
                            button
                            key={file.name}
                            onClick={() => {
                              setCwd((prevCwd) => {
                                const newCwd = `${prevCwd}${file.name}`;
                                return newCwd;
                              });
                            }}
                          >
                            <ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={file.name}
                              sx={{
                                m: 0,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                              primaryTypographyProps={{
                                sx: {
                                  fontSize: 12,
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                },
                              }}
                            />
                          </ListItem>
                        </Paper>
                      ) : (
                        <ListItem
                          button
                          onClick={() => handleFileOnClick(file)}
                          key={file.name}
                        >
                          <ListItemText
                            primary={file.name}
                            sx={{
                              m: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: 12,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              },
                            }}
                          />
                        </ListItem>
                      )}
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            </List>
          ) : (
            <> </>
          )}
        </Box>

        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              Current Layers
            </Typography>
          </Box>

          {layers.length > 0 ? (
            <List
              sx={{
                overflow: "auto",
                height: `${
                  (window.innerHeight - SIGN_OUT_HEIGHT) * LAYER_PROPORTION
                }px`,
              }}
            >
              <TransitionGroup>
                {layers.map((layer) => {
                  return (
                    <Collapse key={layer.uri}>
                      <ListItem
                        button
                        onClick={() => handleLayerOnClick(layer)}
                        key={layer.uri}
                      >
                        <ListItemText
                          primary={`${layer.ext},${layer.uri}`}
                          sx={{
                            m: 0,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: 12,
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            },
                          }}
                        />
                      </ListItem>
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            </List>
          ) : (
            <> </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Finder;
