import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertContext } from "./AlertProvider";
import { Box, Typography, TextField } from "@mui/material";
import { getFilenameFromUri, getUrl } from "../utils/utils";

const getFilenameFromLayers = (layers) => {
  for (let layer of layers) {
    let { uri, ext } = layer;
    if (ext === "tif") {
      return getFilenameFromUri(uri);
    }
  }
};

const FilenamePanel = ({ layers, params }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useContext(AlertContext);
  const initFilename = getFilenameFromLayers(layers);
  const [filename, setFilename] = useState(initFilename);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Needed so that changes on back button or navigate
    setFilename(getFilenameFromLayers(layers));
  }, [layers]);

  const updateUris = (newFilename) => {
    setIsEditing(false);

    // Validation on newFilename
    const validFilenamePattern = /^[a-zA-Z0-9._-]+$/;
    if (!validFilenamePattern.test(newFilename)) {
      console.error(
        "Invalid filename: It must not contain spaces or invalid characters."
      );
      showAlert(
        "Invalid filename: It must not contain spaces or invalid characters.",
        "error"
      );
      setFilename(initFilename);
      return; // Early exit if the filename is invalid
    }

    // Construct new uris
    if (newFilename !== initFilename) {
      let searchString = location.search.replaceAll(initFilename, newFilename);
      navigate(searchString);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    setFilename(initFilename);
  };

  const handleChange = (event) => {
    setFilename(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      updateUris(filename);
    } else if (event.key === "Escape") {
      setIsEditing(false);
      setFilename(initFilename);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 10,
        left: "50%",
        minWidth: 100,
        maxHeight: 60,
        minHeight: 40,
        // height: 40,
        zIndex: 1000,
        backgroundColor: "rgba(255, 255, 255, 0.50)",
        borderRadius: 1,
        boxShadow: 0,
        overflow: "scroll",
        px: isEditing ? 0 : 4,
        py: 1,
        transform: "translateX(-50%)", // Offset by half its width
        display: "flex",
        alignItems: "center",
      }}
      onDoubleClick={handleDoubleClick}
    >
      {isEditing ? (
        <TextField
          value={filename}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          onKeyDown={handleKeyDown}
          variant="outlined"
          size="small"
          inputProps={{
            style: { textAlign: "center" }, // Center the text inside TextField
          }}
        />
      ) : (
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 14,
          }}
        >
          {filename}
        </Typography>
      )}
    </Box>
  );
};

export default FilenamePanel;
