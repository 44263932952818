import { Ontology } from "tulkas-lib/primitives";
import {
  DEFAULT_S3_REGION,
  DEFAULT_INDEX_DIR,
  GLOBAL_ONTOLOGY_DICT,
} from "../constants";

export function getLayersParams(url) {
  const searchableUrl = new URL(url).searchParams;
  let layers = [];
  let currentLayer = {};
  if ((url.match(/&edit/g) || []).length > 1) {
    throw Error("[getLayersParams] Multiple edit flags found");
  }
  for (const [k, v] of searchableUrl.entries()) {
    switch (k) {
      case "uri": {
        if (currentLayer["uri"]) {
          if (!currentLayer["region"]) {
            currentLayer["region"] = DEFAULT_S3_REGION;
          }
          layers.push(currentLayer);
        }
        const ext = v.includes(".") ? v.split(".").pop() : null;
        const isLocal = !v.includes("s3:");
        if (ext !== "tif") {
          currentLayer = { ontology: new Ontology(GLOBAL_ONTOLOGY_DICT) };
        }

        currentLayer = {
          ...currentLayer,
          uri: v,
          ext: ext,
          isLocal: isLocal,
        };
        break;
      }
      case "region": {
        if (currentLayer["uri"]) {
          currentLayer["region"] = v;
        } else {
          throw Error(
            "[getLayersParams] URL incorrectly formed, region without uri"
          );
        }
        break;
      }
      case "edit": {
        if (
          currentLayer["uri"] &&
          ["lngj/", "geojson"].includes(currentLayer["ext"])
        ) {
          currentLayer["edit"] = true;
        } else {
          throw Error(
            "[getLayersParams] URL incorrectly formed, edit added to non-ln filetype"
          );
        }
        break;
      }
      case "visible": {
        if (currentLayer["uri"]) {
          if (v) {
            currentLayer["visible"] = v === "true";
          } else {
            currentLayer["visible"] = true;
          }
        } else {
          throw Error(
            "[getLayersParams] URL incorrectly formed, visible without uri"
          );
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  if (currentLayer["uri"]) {
    if (!currentLayer["region"]) {
      currentLayer["region"] = DEFAULT_S3_REGION;
    }

    layers.push(currentLayer);
  }

  let params = Object.fromEntries(searchableUrl);
  if (!params.indexDir) {
    params.indexDir = DEFAULT_INDEX_DIR;
  }

  return {
    layers: layers,
    params: params,
  };
}
