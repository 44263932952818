/**
 * AlertProvider.js
 *
 * Context provider component for managing alerts in the application.
 */

import { createContext, useState } from "react";
import { Alert, Snackbar, Button } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const DialogComponent = ({
  open,
  dialogTitle, // str
  dialogText, // str
  onClose, // Function
  onContinue, // Function
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onContinue();
            onClose();
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [dialog, setDialog] = useState(null);

  const showAlert = (message, severity) => {
    const newAlert = {
      id: message,
      message,
      severity,
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  const showDialog = (dialogTitle, dialogText, onContinue) => {
    const newDialog = {
      dialogTitle,
      dialogText,
      onContinue,
    };

    setDialog(newDialog);
  };

  return (
    <AlertContext.Provider value={{ showAlert, removeAlert, showDialog }}>
      {children}
      <Snackbar
        open={alerts.length > 0}
        autoHideDuration={5000}
        onClose={() => removeAlert(alerts[0].id)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={alerts[0]?.severity}
          onClose={() => removeAlert(alerts[0].id)}
        >
          {alerts[0]?.message}
        </Alert>
      </Snackbar>

      {/* TODO: Move below to UI Provider */}
      {dialog !== null ? (
        <DialogComponent
          open={dialog !== null}
          dialogTitle={dialog.dialogTitle}
          dialogText={dialog.dialogText}
          onClose={() => {
            setDialog(null);
          }}
          onContinue={dialog.onContinue}
        />
      ) : (
        <></>
      )}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
