import {
  Array as BaseArray,
  ArrayProps,
  Group as BaseGroup,
} from "./lib/hierarchy";
import { registry } from "./lib/codec-registry";
import { KeyError, NodeNotFoundError } from "./lib/errors";
import { is_dtype, json_decode_object, json_encode_object } from "./lib/util";

// Edit: Manual Import numcodecs
// import type { Codec, CodecConstructor } from './numcodecs/types';
// import "./lib/numcodecs/wkb.mjs";

import type {
  AbsolutePath,
  Async,
  Attrs,
  CreateArrayProps,
  DataType,
  Deref,
  Readable,
  Writeable,
} from "./types";
import type { DataTypeQuery, NarrowDataType } from "./dtypes";

import { Codec, CodecConstructor, VlenBytes } from "numcodecs";

export { slice } from "./lib/util";
export { registry } from "./lib/codec-registry";

function getTimeElapsed(initTime: number) {
  return Math.floor((Date.now() - initTime) / 1000);
}

type Config = Record<string, any>;
type CodecImporter = () => Promise<{ fromConfig: (config: Config) => Codec }>;

async function get_attrs<Store extends Readable | Async<Readable>>(
  store: Store,
  path: AbsolutePath
) {
  const maybe_bytes = await store.get(attrs_key(path));
  const attrs: Attrs = maybe_bytes ? json_decode_object(maybe_bytes) : {};
  return attrs;
}

/**
 * Zarr v2 Array
 * @category Hierarchy
 */
export class Array<
  Dtype extends DataType,
  Store extends Readable | Async<Readable> = Async<Readable>,
  Path extends AbsolutePath = AbsolutePath
> extends BaseArray<Dtype, Store, Path> {
  /** @hidden */
  private _attrs?: Attrs;
  constructor(props: ArrayProps<Dtype, Store, Path> & { attrs?: Attrs }) {
    super(props);
    this._attrs = props.attrs;
  }
  /** @hidden */
  protected chunk_key(chunk_coords: number[]) {
    const prefix = key_prefix(this.path);
    const chunk_identifier = chunk_coords.join(this.chunk_separator);
    return `${prefix}${chunk_identifier}` as AbsolutePath;
  }
  /**
   * Get attributes for Array. Loads attributes from underlying
   * Stores and caches result to avoid accessing store multiple times.
   */
  async attrs() {
    if (this._attrs) return this._attrs;
    const attrs = (this._attrs = get_attrs(this.store, this.path));
    return attrs;
  }

  /**
   * A helper method to narrow `zarr.Array` Dtype.
   *
   * ```typescript
   * let arr: zarr.Array<DataType, FetchStore, "/"> = zarr.get_array(store);
   *
   * // Option 1: narrow by scalar type (e.g. "string", "bigint", "number")
   * if (arr.is("bigint")) {
   *   // zarr.Array<"<u8" | ">u8" | "<i8" | ">i8", FetchStore, "/">
   * }
   *
   * // Option 2: narrow by dtype without endianess
   * if (arr.is("i4")) {
   *   // zarr.Array<"<i4" | ">i4", FetchStore, "/">
   * }
   *
   * // Option 3: exact match
   * if (arr.is("<f4")) {
   *   // zarr.Array<"<f4", FetchStore, "/">
   * }
   * ```
   */
  is<Query extends DataTypeQuery>(
    query: Query
  ): this is Array<NarrowDataType<Dtype, Query>, Store, Path> {
    return is_dtype(this.dtype, query);
  }
}

/**
 * Zarr v2 Group
 * @category Hierarchy
 */
export class Group<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath = AbsolutePath
> extends BaseGroup<Store, Path> {
  private _attrs?: Attrs;
  constructor(props: { store: Store; path: Path; attrs?: Attrs }) {
    super(props);
    this._attrs = props.attrs;
  }
  /**
   * Get attributes for Group. Loads attributes from underlying
   * Store and caches result to avoid accessing store multiple times.
   */
  async attrs() {
    if (this._attrs) return this._attrs;
    const attrs = (this._attrs = get_attrs(this.store, this.path));
    return attrs;
  }
}

function encode_codec_metadata(codec: Codec) {
  // @ts-ignore
  return { id: codec.constructor.codecId, ...codec };
}

/** Extracted from numcodecs for wkb */
const HEADER_LENGTH = 4;

const read4Bytes = (buf: Uint8Array) => {
  return new Int32Array(buf)[0];
};
interface None {}

const VLenBytes: CodecConstructor<None> = class VLenBytes implements Codec {
  public static codecId = "wkb";

  constructor() {}

  static fromConfig({}): VLenBytes {
    return new VLenBytes();
  }

  /*TODO: implement encode of VLenBytes*/
  encode(data: Uint8Array[]): Uint8Array {
    return new Uint8Array();
  }

  decode(data: Uint8Array, out?: Uint8Array): Uint8Array {
    /*
    let ptr = 0;
    const dataEnd = ptr + data.length;
    const length = read4Bytes(data.slice(0, HEADER_LENGTH));

    if (data.length < HEADER_LENGTH) {
      throw new Error('corrupt buffer, missing or truncated header');
    }

    ptr += HEADER_LENGTH;

    const output = new Array<Uint8Array>(length);
    for (let i = 0; i < length; i += 1) {
      if (ptr + 4 > dataEnd) {
        throw new Error('corrupt buffer, data seem truncated');
      }
      const l = read4Bytes(data.slice(ptr, ptr + 4));
      ptr += 4;
      if (ptr + l > dataEnd) {
        throw new Error('corrupt buffer, data seem truncated');
      }
      output[i] = data.slice(ptr, ptr + l);
      ptr += l;
    }

    const maxLength = output.reduce((max, entry) => Math.max(max, entry.length), 0)

    const paddedOutput = output.map((entry) => {
      if (entry.length < maxLength) {
        const paddedEntry = new Uint8Array(maxLength);
        paddedEntry.set(entry, 0); // Copy existing values to padded entry
        return paddedEntry;
      }
      return entry; // No padding required for this entry
    });

    // Concatenate all arrays
    const totalLength = paddedOutput.reduce((total, arr) => total + arr.length, 0);
    const concatenatedArray = new Uint8Array(totalLength);
    let offset = 0;
    paddedOutput.forEach((uint8Array) => {
      concatenatedArray.set(uint8Array, offset);
      offset += uint8Array.length;
    });

    if (out !== undefined) {
      out.set(concatenatedArray);
    }
    return concatenatedArray;
    */

    if (out !== undefined) {
      out.set(data);
    }
    return data;
  }
};

/**  */

interface None {}
async function get_codec(config: Record<string, any>): Promise<Codec> {
  const importer = registry.get(config.id);
  if (!importer) throw new Error("missing codec " + JSON.stringify(config.id));
  // console.log("zarrita: ", { importer });
  // const ctr = await importer();
  // TODO: replace manual

  if (config.id === "wkb") {
    const ctr = VLenBytes;
    const configWithId = { id: config.id }; // Add the 'id' property to the config object
    return ctr.fromConfig(configWithId);
  } else {
    const ctr = await importer();
    const configWithId = { id: config.id }; // Add the 'id' property to the config object
    return ctr.fromConfig(configWithId);
  }

  // const ctr = await import(/* @vite-ignore */ `./numcodecs/${config.id}.js`).then((m) => m.default) as CodecConstructor<None>;
  // const ctr = import(/* @vite-ignore */ `./numcodecs/${config.id}.mjs`).then((m) => m.default);

  // return ctr.fromConfig(config);
}

/** Zarr v2 Array Metadata. Stored as JSON with key `.zarray`. */
export interface ArrayMetadata<Dtype extends DataType> {
  zarr_format: 2;
  shape: number[];
  chunks: number[];
  dtype: Dtype;
  compressor: null | Record<string, any>;
  fill_value: import("./types").Scalar<Dtype> | null;
  order: "C" | "F";
  filters: null | Record<string, any>[];
  dimension_separator?: "." | "/";
}

/** Zarr v2 Group Metadata. Stored as JSON with key `.zgroup`. */
export interface GroupMetadata {
  zarr_format: 2;
}

function key_prefix<Path extends AbsolutePath>(
  path: Path
): Path extends "/" ? "/" : `${Path}/` {
  // @ts-ignore
  return path === "/" ? path : `${path}/`;
}

const array_meta_key = (path: AbsolutePath) =>
  `${key_prefix(path)}.zarray` as const;

const group_meta_key = (path: AbsolutePath) =>
  `${key_prefix(path)}.zgroup` as const;

const attrs_key = (path: AbsolutePath) => `${key_prefix(path)}.zattrs` as const;

function deref<
  Store extends Readable | Async<Readable>,
  Path extends string,
  NodePath extends AbsolutePath
>(
  grp: Group<Store, NodePath>,
  path: Path
): { store: Store; path: Deref<Path, NodePath> };

function deref<Store, Path extends AbsolutePath>(
  store: Store,
  path: Path
): { store: Store; path: Path };

function deref<Store extends Readable | Async<Readable>>(
  node: Store | Group<Store>,
  path: any
) {
  if ("store" in node) {
    return { store: node.store, path: node.deref(path) };
  }
  return { store: node, path };
}

async function _get_array<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath
>(store: Store, path: Path) {
  console.log("[v2.ts] Getting array...");

  let _initTime;

  _initTime = Date.now();
  const meta_key = array_meta_key(path);
  console.log(`[v2.ts] Get array_meta_key in ${getTimeElapsed(_initTime)} s`);

  _initTime = Date.now();
  const meta_doc = await store.get(meta_key);
  console.log(`[v2.ts] store.get(meta_key) in ${getTimeElapsed(_initTime)} s`);

  if (!meta_doc) {
    throw new NodeNotFoundError(path);
  }
  _initTime = Date.now();
  const meta: ArrayMetadata<DataType> = json_decode_object(meta_doc);
  console.log(`[v2.ts] Decode json object in ${getTimeElapsed(_initTime)} s`);

  _initTime = Date.now();
  const filters = meta.filters
    ? await Promise.all(meta.filters.map(get_codec))
    : undefined;
  console.log(
    `[v2.ts] get_codec for all meta.filters ${getTimeElapsed(_initTime)} s`
  );

  _initTime = Date.now();
  const compressor = meta.compressor
    ? await get_codec(meta.compressor)
    : undefined;
  console.log(
    `[v2.ts] get_codec for meta.compressor in ${getTimeElapsed(_initTime)} s`
  );

  _initTime = Date.now();
  const arr = new Array({
    store: store,
    path,
    shape: meta.shape,
    dtype: meta.dtype,
    chunk_shape: meta.chunks,
    chunk_separator: meta.dimension_separator ?? ".",
    filters: filters,
    compressor: compressor,
    fill_value: meta.fill_value,
    order: meta.order,
  });
  console.log(`[v2.ts] Instantiate arr in ${getTimeElapsed(_initTime)} s`);

  return arr;
}

/**
 * Open Array relative to Group
 *
 * ```typescript
 * let grp = await zarr.get_group(store, "/path/to/grp");
 * let arr = await zarr.get_array(grp, "array");
 * // or
 * let arr = await zarr.get_array(grp, "/path/to/grp/array");
 * ```
 */
export function get_array<
  Store extends Readable | Async<Readable>,
  Path extends string,
  NodePath extends AbsolutePath
>(
  group: Group<Store, NodePath>,
  path: Path
): Promise<Array<DataType, Store, Deref<Path, NodePath>>>;

/**
 * Open Array from store using an absolute path
 *
 * ```typescript
 * let arr = await get_array(store, "/path/to/array");
 * ```
 */
export function get_array<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath
>(store: Store, path: Path): Promise<Array<DataType, Store, Path>>;

/**
 * Open root as Array
 *
 * ```typescript
 * let arr = await get_array(store);
 * ```
 */
export function get_array<Store extends Readable | Async<Readable>>(
  store: Store
): Promise<Array<DataType, Store, "/">>;

/**
 * Open an Array from a Group or Store.
 * @category Read
 */
export async function get_array<Store extends Readable | Async<Readable>>(
  node: Store | Group<Store>,
  _path: any = "/"
) {
  const { store, path } = deref(node, _path);
  return _get_array(store as Store, path);
}

async function _get_group<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath
>(store: Store, path: Path) {
  const meta_key = group_meta_key(path);
  const meta_doc = await store.get(meta_key);
  if (!meta_doc) {
    throw new NodeNotFoundError(path);
  }
  return new Group({ store, path });
}

/**
 * Open Group relative to another Group
 *
 * ```typescript
 * let group = await zarr.get_group(grp, "path/to/grp");
 * ```
 */
export function get_group<
  Store extends Readable | Async<Readable>,
  Path extends string,
  NodePath extends AbsolutePath
>(
  group: Group<Store, NodePath>,
  path: Path
): Promise<Group<Store, Deref<Path, NodePath>>>;

/**
 * Open Group from store via absolute path
 *
 * ```typescript
 * let group = await zarr.get_group(store, "/path/to/grp");
 * ```
 */
export function get_group<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath
>(store: Store, path: Path): Promise<Group<Store, Path>>;

/**
 * Open root as Group
 *
 * ```typescript
 * let group = await zarr.get_group(store);
 * ```
 */
export function get_group<Store extends Readable | Async<Readable>>(
  store: Store
): Promise<Group<Store, "/">>;

/**
 * Open Group from Store or anthoer Group
 * @category Read
 */
export async function get_group<Store extends Readable | Async<Readable>>(
  node: Store | Group<Store>,
  _path: any = "/"
) {
  const { store, path } = deref(node, _path);
  return _get_group(store as Store, path as AbsolutePath);
}

/**
 * Open Node (Group or Array) from another Group
 *
 * ```typescript
 * let node = await zarr.get(grp, "path/to/node");
 * if (node instanceof zarr.Group) {
 *   // zarr.Group
 * } else {
 *   // zarr.Array
 * }
 * ```
 */
export function get<
  Store extends Readable | Async<Readable>,
  Path extends string,
  NodePath extends AbsolutePath
>(
  group: Group<Store, NodePath>,
  path: Path
): Promise<
  | Array<DataType, Store, Deref<Path, NodePath>>
  | Group<Store, Deref<Path, NodePath>>
>;

/**
 * Open Node (Group or Array) from store via absolute path
 *
 * ```typescript
 * let node = await zarr.get(store, "/path/to/node");
 * if (node instanceof zarr.Group) {
 *   // zarr.Group
 * } else {
 *   // zarr.Array
 * }
 * ```
 */
export function get<
  Store extends Readable | Async<Readable>,
  Path extends AbsolutePath
>(
  store: Store,
  path: Path
): Promise<Array<DataType, Store, Path> | Group<Store, Path>>;

/**
 * Open root Node (Group or Array) from store
 *
 * ```typescript
 * let node = await zarr.get(store);
 * if (node instanceof zarr.Group) {
 *   // zarr.Group
 * } else {
 *   // zarr.Array
 * }
 * ```
 */
export function get<Store extends Readable | Async<Readable>>(
  store: Store
): Promise<Array<DataType, Store, "/"> | Group<Store, "/">>;

/** @category Read */
export async function get<Store extends Readable | Async<Readable>>(
  node: Store | Group<Store>,
  _path: any = "/"
) {
  const { store, path } = deref(node, _path);
  try {
    return await _get_array(store as Store, path);
  } catch (err) {
    if (!(err instanceof NodeNotFoundError)) {
      throw err;
    }
  }
  // try explicit group
  try {
    return await _get_group(store as Store, path);
  } catch (err) {
    if (!(err instanceof NodeNotFoundError)) {
      throw err;
    }
  }
  throw new KeyError(path);
}

async function _create_group<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends AbsolutePath
>(store: Store, path: Path, attrs?: Attrs) {
  const meta_doc = json_encode_object({ zarr_format: 2 } as GroupMetadata);
  const meta_key = group_meta_key(path);
  await store.set(meta_key, meta_doc);
  if (attrs) {
    await store.set(attrs_key(path), json_encode_object(attrs));
  }
  return new Group({ store, path, attrs: attrs ?? {} });
}

/**
 * Create Group relative to another Group.
 *
 * ```typescript
 * let root = await zarr.get_group(store);
 * let group = await zarr.create_group(root, "new/grp");
 * ```
 *
 * @category Creation
 */
export function create_group<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends string,
  NodePath extends AbsolutePath
>(
  group: Group<Store, NodePath>,
  path: Path,
  props?: { attrs?: Attrs }
): Promise<Group<Store, Deref<Path, NodePath>>>;

/**
 * Create Group from store via absolute path
 *
 * ```typescript
 * let group = await zarr.create_group(store, "/new/grp");
 * ```
 *
 * @category Creation
 */
export function create_group<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends AbsolutePath
>(
  store: Store,
  path: Path,
  props?: { attrs?: Attrs }
): Promise<Group<Store, Path>>;

export async function create_group<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>
>(node: Store | Group<Store>, _path: any = "/", props: { attrs?: Attrs } = {}) {
  const { store, path } = deref(node, _path);
  return _create_group(store as Store, path as AbsolutePath, props.attrs);
}

async function _create_array<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends AbsolutePath,
  Dtype extends DataType
>(store: Store, path: Path, props: CreateArrayProps<Dtype>) {
  const shape = props.shape;
  const dtype = props.dtype;
  const chunk_shape = props.chunk_shape;
  const compressor = props.compressor;
  const chunk_separator = props.chunk_separator ?? ".";
  const filters = props.filters;
  const order = props.order ?? "C";

  const meta: ArrayMetadata<Dtype> = {
    zarr_format: 2,
    shape,
    dtype,
    chunks: chunk_shape,
    dimension_separator: chunk_separator,
    order: order,
    fill_value: props.fill_value ?? null,
    filters: filters ? filters.map(encode_codec_metadata) : null,
    compressor: compressor ? encode_codec_metadata(compressor) : null,
  };

  // serialise and store metadata document
  const meta_doc = json_encode_object(meta);
  const meta_key = array_meta_key(path);

  await store.set(meta_key, meta_doc);

  if (props.attrs) {
    await store.set(attrs_key(path), json_encode_object(props.attrs));
  }

  return new Array({
    store: store,
    path: path,
    shape: meta.shape,
    dtype: dtype,
    chunk_shape: meta.chunks,
    chunk_separator: chunk_separator,
    compressor: compressor,
    filters: filters,
    fill_value: meta.fill_value,
    attrs: props.attrs ?? {},
    order: order,
  });
}

/**
 * Create Array relative to a Group.
 *
 * ```typescript
 * let grp = zarr.get_group(store, "/path/to/grp");
 * let arr = await zarr.create_array(grp, "data", {
 *   dtype: "<i4",
 *   shape: [100, 100],
 *   chunk_shape: [20, 20],
 * });
 * ```
 *
 * @category Creation
 */
export function create_array<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends string,
  NodePath extends AbsolutePath,
  Dtype extends DataType
>(
  group: Group<Store, NodePath>,
  path: Path,
  props: CreateArrayProps<Dtype>
): Promise<Array<Dtype, Store, Deref<Path, NodePath>>>;

/**
 * Create Array in store via absolute path
 *
 * ```typescript
 * let arr = await zarr.create_array(store, "/data", {
 *   dtype: "<i4",
 *   shape: [100, 100],
 *   chunk_shape: [20, 20],
 * });
 * ```
 *
 * @category Creation
 */
export function create_array<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Path extends AbsolutePath,
  Dtype extends DataType
>(
  store: Store,
  path: Path,
  props: CreateArrayProps<Dtype>
): Promise<Array<Dtype, Store, Path>>;

export async function create_array<
  Store extends (Readable & Writeable) | Async<Readable & Writeable>,
  Dtype extends DataType
>(node: Store | Group<Store>, _path: any, props: CreateArrayProps<Dtype>) {
  const { store, path } = deref(node, _path);
  return _create_array(store as Store, path as AbsolutePath, props);
}

/**
 * Check existence of Node (Group or Array) relative to Group
 *
 * ```typescript
 * let grp = zarr.get_group(store, "/path/to/grp");
 * await zarr.has(grp, "data")
 * await zarr.has(grp, "/path/to/grp/data");
 * ```
 */
export function has<Store extends Readable | Async<Readable>>(
  group: Group<Store, AbsolutePath>,
  path: string
): Promise<boolean>;

/**
 * Check existence of Node (Group or Array) in store
 *
 * ```typescript
 * await zarr.has(store, "/path/to/grp/data");
 * ```
 */
export function has<Store extends Readable | Async<Readable>>(
  store: Store,
  path: AbsolutePath
): Promise<boolean>;

/** @category Read */
export async function has<Store extends Readable | Async<Readable>>(
  node: Store | Group<Store>,
  _path: any
) {
  const { store, path } = deref(node, _path);
  // TODO: implement using `has` if available on store.
  return get(store as Store, path as AbsolutePath)
    .then(() => true)
    .catch((err) => {
      if (err instanceof KeyError) {
        return false;
      }
      throw err;
    });
}
