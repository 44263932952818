import { useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { Authenticator, View, Text } from "@aws-amplify/ui-react";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const Main = () => {
  const { tokens } = useTheme();
  const components = {
    SignIn: {
      Header() {
        return (
          <div>
            <View textAlign="center" padding={tokens.space.large}>
              <Text
                style={{
                  fontSize: 28,
                  color: "#14656C",
                  fontWeight: 700,
                  marginBottom: -32,
                }}
              >
                Valar Labs
              </Text>
            </View>
          </div>
        );
      },
      Footer() {
        const { tokens } = useTheme();
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]} fontSize={14}>
              Questions? Call (888) 862-0232
            </Text>
          </View>
        );
      },
    },
  };

  // Update AWS Credentials for entire app
  const { route } = useAuthenticator((context) => [context.route]);

  // Use the value of route to decide which page to render
  return route === "authenticated" ? (
    <Outlet />
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Authenticator
        socialProviders={[]}
        signUpAttributes={["email"]}
        hideSignUp={true}
        components={components}
      />
    </Box>
  );
};

export default Main;
