import { useRef, useEffect, useState, useContext } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Portal } from "@mui/base/Portal";
import CollectionsIcon from "@mui/icons-material/Collections";

import * as L from "leaflet";
import {
  GeoRasterLayer,
  ElenLayer,
  AnnotationControl,
} from "tulkas-lib/leaflet";
import { Store } from "tulkas-lib/primitives";
import { EditPanel } from "./EditPanel";
import Handlers from "./Handlers";

import {
  INIT_MAP_BOUNDS,
  TILE_SIZE,
  DEFAULT_S3_REGION,
  GLOBAL_ONTOLOGY,
} from "../constants";

import { AlertContext } from "./AlertProvider";
import { getSignedUrlFromUri } from "../utils/utils";
import {
  displayCursorLocation,
  addClickCopyHandler,
  displayMapZoom,
} from "../utils/ux";
import { fetchAndDisplayImage } from "../utils/img";
import CollapsablePanel from "../components/CollapsablePanel";
import OntologyControl from "./OntologyControl";
import LabelImages from "./LabelImages";

const Viewer = ({ layers, params, refs }) => {
  const { showAlert } = useContext(AlertContext);

  const [map, setMap] = useState();
  const [isLoading, setIsLoading] = useState();
  const [bounds, setBounds] = useState(INIT_MAP_BOUNDS);
  const [isFinalBounds, setIsFinalBounds] = useState(false);
  const [editLayerObj, setEditLayerObj] = useState();
  const [labelImages, setLabelImages] = useState(null);

  const mapRef = useRef(null);
  const layerObjsRef = useRef({}); // Use ref, otherwise race condition on state update

  const MAX_ZOOM = 5;
  const MIN_ZOOM = -10;

  const createLayer = (layer, url, uri, map) => {
    const url_sign = async (
      uri,
      operation = "getObject",
      contentType = undefined
    ) => {
      if (!uri.includes("s3")) {
        return uri;
      } else {
        return await await getSignedUrlFromUri(
          uri,
          layer.region,
          operation,
          contentType
        );
      }
    };

    switch (layer.ext) {
      case "tif": {
        setIsLoading(true);
        GeoRasterLayer.getAllImageSets(url)
          .then(({ GeoTIFF, GeoTIFFImages, LabelImages }) => {
            let layerObj = new GeoRasterLayer(
              GeoTIFF,
              GeoTIFFImages,
              {
                minZoom: MIN_ZOOM,
                updateWhenZooming: true,
                debugLevel: 0,
              },
              uri
            );

            const slideBounds = layerObj.getBounds();
            if (map) {
              try {
                if (!isFinalBounds) {
                  setBounds(slideBounds);
                  map.fitBounds(slideBounds);
                }
                layerObj.addTo(map);
              } catch (err) {
                console.log("[Viewer] Georaster error: ", err);
              }
            }

            setIsLoading(false);
            layerObjsRef.current[layer.uri] = layerObj;

            Promise.all(
              LabelImages.map((labelImage) => fetchAndDisplayImage(labelImage))
            ).then((images) => {
              setLabelImages(images);
            });
          })
          .catch((err) => {
            showAlert(`tif file not found. Double check .tif uri`, "error");
            setIsLoading(false);
            console.error(`[Viewer] TIF file ${url} not found.`, err);
          });
        break;
      }
      case "ln": {
        const [headUrl, getUrl] = url;
        ElenLayer.from_url(getUrl, headUrl, GLOBAL_ONTOLOGY, map, {
          geometry_var: params.geometry ?? "geometry",
        })
          .then((layerObj) => {
            if (layerObj && map) {
              console.log("Layer Obj: ", layerObj, map);
              if (layer.visible) layerObj.addTo(map);
              layerObjsRef.current[layer.uri] = layerObj;
            }
          })
          .catch((err) => {
            // showAlert(`${layer.uri} not found`, "error");
            console.error("[Viewer] Elen error: ", err);
          });
        break;
      }
      case "lngj/": {
        const store = new Store(uri, url_sign, "geojson.gz");
        let layerObj = new ElenLayer(store, map, layer.ontology, {
          geometry_var: params.geometry ?? "geometry",
        });

        if (layer.edit) {
          layerObj = new AnnotationControl(
            { ontology: layer.ontology },
            layerObj,
            store
          );
          setEditLayerObj(layerObj);
        }
        if (layer.visible) layerObj.addTo(map);
        layerObjsRef.current[layer.uri] = layerObj;
        break;
      }
      case "geojson": {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            let layerObj = L.geoJSON(data, {
              style: function (feature) {
                if (feature.properties && feature.properties.type) {
                  return GLOBAL_ONTOLOGY.options[feature.properties.type];
                }
              },
              onEachFeature: function (feature, layer) {
                if (feature.properties && feature.properties.type) {
                  layer.bindPopup(feature.properties.type);
                }
              },
              pointToLayer: function (feature, latlng) {
                var geojsonMarkerOptions = {
                  radius: 6,
                  fillColor:
                    GLOBAL_ONTOLOGY.options[feature.properties.type]["color"],
                  color: "#000",
                  weight: 1,
                  opacity: 1,
                  fillOpacity: 0.2,
                };
                return L.circleMarker(latlng, geojsonMarkerOptions);
              },
            });

            if (layer.edit) {
              layerObj = new AnnotationControl(
                { ontology: layer.ontology },
                layerObj,
                new Store(uri, url_sign)
              );
              setEditLayerObj(layerObj);
            } else {
              layerObj.updateOntology = ([key, _], e) => {
                const selected = e.target.checked;
                layerObj.clearLayers();
                layer.ontology.options[key].visible = selected;
                layerObj.addData({
                  ...data,
                  features: data.features.filter((feature) => {
                    return (
                      layer.ontology.options[feature.properties.type]
                        ?.visible ?? false
                    );
                  }),
                });
              };
            }

            if (layer.visible) layerObj.addTo(map);
            layerObjsRef.current[layer.uri] = layerObj;
          })
          .catch((err) => {
            // showAlert(`${layer.uri} not found`, "error");
            console.error("[Viewer] GeoJson error: ", err);
          });
        break;
      }
      default: {
        throw Error(
          `[Viewer] Unsupported filetype with extension ${layer.ext} for ${layer.uri}.`
        );
      }
    }
  };

  useEffect(() => {
    var CRSPixel = L.Util.extend(L.CRS.Simple, {
      transformation: new L.Transformation(1, 0, 1, 0),
    });

    if (mapRef.current) {
      var map = L.map(mapRef.current, {
        crs: CRSPixel,
        center: [0, 0],
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        scrollWheelZoom: false,
        smoothWheelZoom: true,
        zoomAnimationThreshold: 2,
        zoomControl: false,
        boxZoom: false,
      }).fitBounds(bounds);

      displayMapZoom(map);
      addClickCopyHandler(map, showAlert);
      displayCursorLocation(map);
      setMap(map);
      // VISH FIX ISSUE LATER
      // setCrosshairOnCommand(map);

      for (const layer of layers) {
        const { uri, region, ext, isLocal } = layer;
        if (!ext) continue;
        try {
          if (isLocal)
            createLayer(layer, ext === "ln" ? [uri, uri] : uri, uri, map);
          else
            getSignedUrlFromUri(
              uri,
              region,
              ext === "ln" ? ["headObject", "getObject"] : "getObject"
            ).then((url) => {
              createLayer(layer, url, uri, map);
            });
        } catch (err) {
          console.error(`[Viewer] Error loading from uri: ${uri} `, err);
        }
      }

      const x = parseFloat(params?.x),
        y = parseFloat(params?.y);
      if (x && y) {
        let marker;
        let markerBounds = [
          [y - TILE_SIZE / 2, x - TILE_SIZE / 2],
          [y + TILE_SIZE / 2, x + TILE_SIZE / 2],
        ];
        marker = L.marker([y, x]).addTo(map).bindTooltip(`${x}μ, ${y}μ`);
        map.on("click", function (e) {
          if (marker) {
            map.removeLayer(marker);
          }
        });
        setIsFinalBounds(true);
        setBounds(markerBounds);
        map.fitBounds(markerBounds);
      }

      return () => {
        try {
          map.remove();
        } catch (err) {
          console.warn("[Viewer] Error removing map: ", err);
        }
      };
    }
  }, [mapRef, layers, isFinalBounds]);

  return (
    <div
      ref={mapRef}
      style={{
        height: "100%",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <></>
      )}

      {/* Portal to move components to parent component DOM 
          and prevent event propagation to Map */}
      <Portal container={() => refs.editPanelRef.current}>
        <EditPanel editLayerObj={editLayerObj} />
      </Portal>
      {Object.values(layers).some((layer) => layer.ontology) && (
        <Portal container={() => refs.ontologyRef.current}>
          <OntologyControl
            layers={layers}
            layerObjs={layerObjsRef.current}
            editLayerObj={editLayerObj}
            map={map}
          />
        </Portal>
      )}
      <Handlers editLayerObj={editLayerObj} />

      <Portal container={() => refs.labelImagesRef.current}>
        <CollapsablePanel
          right={10}
          bottom={20}
          height={100}
          width={
            labelImages && labelImages.length > 0
              ? `${50 + labelImages.length * 116}px`
              : "400px"
          }
          closedIcon={<CollectionsIcon />}
          openedStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100vh",
          }}
          childrenPosition="left"
        >
          <LabelImages labelImages={labelImages} />
        </CollapsablePanel>
      </Portal>
    </div>
  );
};

export default Viewer;
