import * as elen from "./elen_io";
import { TileBuffer } from "./tilebuffer";
import SAM from "./sam";
import { GridManager } from "./spatial";
import { GeoJSON } from "./geojson_io";
import { Store } from "./store";

class Ontology {
  constructor(options, default_options = {}) {
    this.options = options;
    this.default_options = default_options;
  }

  toJSON() {
    const json = {};
    ["options", "default_options"].forEach((key) => {
      json[key] = this[key];
    });
    return json;
  }

  static fromJSON(json) {
    const obj = new Ontology();
    Object.keys(json).forEach((key) => {
      obj[key] = json[key];
    });
    return obj;
  }

  optionsAvailable() {
    let all_keys = Object.entries(this.options).map((_, val) => {
      return val.keys();
    });
    return [...new Set([].concat(...all_keys))];
  }
}

export { elen, GeoJSON, Store, Ontology, TileBuffer, SAM, GridManager };
