import { useState, useRef, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import ListIcon from "@mui/icons-material/List";

import CollapsablePanel from "../components/CollapsablePanel";
import Viewer from "../components/Viewer";
import Finder from "../components/Finder";

import { updateAWSCredentials } from "../utils/utils";
import { getLayersParams } from "../loaders/url";
import FilenamePanel from "../components/FilenamePanel";

import { ENVS } from "../constants";

const ENV = process.env.REACT_APP_ENV;
async function ViewerPageLoader({ request }) {
  const { layers, params } = getLayersParams(request.url);
  return {
    loaderLayers: layers,
    loaderParams: params,
  };
}

const ViewerPage = () => {
  const { loaderLayers, loaderParams } = useLoaderData();
  const windowHeight = window.innerHeight;
  const [layers, setLayers] = useState(loaderLayers);
  const [params, setParams] = useState(loaderParams);
  const [finderOpened, setFinderOpened] = useState(params.finderOpen);
  const [labelImagesOpened, setLabelImagesOpened] = useState(false);

  const { user } = useAuthenticator((context) => [context.user]);
  const idToken = user.getSignInUserSession().getIdToken().getJwtToken();
  updateAWSCredentials(idToken);

  const editPanelRef = useRef(null);
  const ontologyRef = useRef(null);
  const labelImagesRef = useRef(null);

  const location = window.location.href;

  useEffect(() => {
    const { layers, params } = getLayersParams(location);
    setLayers(layers);
    setParams(params);
  }, [location]);

  return (
    <Box
      sx={{
        height: windowHeight,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {layers.length > 0 ? (
        <Viewer
          layers={layers}
          params={params}
          refs={{
            ontologyRef,
            editPanelRef,
            labelImagesRef,
          }}
        />
      ) : (
        <></>
      )}

      <FilenamePanel layers={layers} params={params} />

      {/* Don't include Finder in production. */}
      {ENV === ENVS.dev ? (
        <CollapsablePanel
          left={10}
          top={10}
          width={250}
          height={"97vh"}
          closedIcon={<ListIcon />}
          opened={finderOpened}
          setOpened={setFinderOpened}
        >
          <Finder layers={layers} params={params} />
        </CollapsablePanel>
      ) : (
        <></>
      )}

      <Box ref={labelImagesRef} />
      <Box ref={ontologyRef} />
      <Box ref={editPanelRef} />
    </Box>
  );
};

export { ViewerPage, ViewerPageLoader };
