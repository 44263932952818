// import * as L from "leaflet";
import * as GeoTIFF from "Util/geotiff/geotiff.js";

/* CONSTANTS */
const DEFAULT_MPP = 0.25;
const INCH2CM = 2.54;

/**
 *
 */
const GeoRasterLayer = L.GridLayer.extend({
  options: {
    updateWhenIdle: false,
    updateWhenZooming: true,
    keepBuffer: 16,
    maxImageCacheCount: 200, // Edit: max number of images to hold in cache
    debugLevel: 0,
    noWrap: true,
    workers: "all",
  },

  /**
   *
   */
  initialize: function (tiff, images, options, uri = null) {
    try {
      this.uri = uri;
      this._ready = false;
      this.workers = options.workers || this.options.workers;
      if (this.workers === "all") {
        this._pool = new GeoTIFF.Pool();
      } else if (Number.isInteger(this.workers) && this.workers > 0) {
        this._pool = new GeoTIFF.Pool(this.workers);
      } else {
        // Default behavior is to use as many workers as possible
        this._pool = new GeoTIFF.Pool();
      }

      this.GeoTIFF = tiff;
      this.GeoTIFFImages = images;
      this.debugLevel = options.debugLevel || 0;
      this._setupLevels();

      this._num_levels = images.length;
      this._mpp = _extractMPP.call(this) || options.mpp || DEFAULT_MPP;

      // See docs for explanation of computation
      this._scale_factor = this._mpp * Math.pow(2, this._num_levels - 1); // Simplification of w / w_0
      // Find the closest multiple to 2, solving equation $2^k < this._scale_factor$ for k
      // Rationale is so that map can zoom in and increment levels properly
      this._minNativeZoom = -1 * Math.floor(Math.log2(this._scale_factor));

      // Scale factor represents how much left image should expand to micron scale
      // i.e. Each tile will already by 2^(-this._minNativeZoom) in Leaflet pixels
      // It needs to be a bit larger in order to perfectly encapsulate all micron units
      const tileSize =
        this._tileWidth *
        (this._scale_factor / Math.pow(2, -this._minNativeZoom));

      // Zoom is used for indexing,
      // so we set maxNativeZoom to avoid going out of bounds on very high zoom
      this._maxNativeZoom = this._minNativeZoom + this._num_levels - 1;

      options.tileSize = tileSize;
      options.minNativeZoom = this._minNativeZoom;
      options.maxNativeZoom = this._maxNativeZoom;

      this.micronHeight = this._mpp * this.height;
      this.micronWidth = this._mpp * this.width;
      this.bounds = [
        [this.micronHeight, this.micronWidth],
        [0, 0],
      ];
      options.bounds = this.bounds;

      L.Util.setOptions(this, options);
    } catch (error) {
      console.error("ERROR initializing GeoTIFFLayer", error);
    }
  },

  statics: {
    getAllImageSets: async function (url, options) {
      let tiff = await (url instanceof File
        ? GeoTIFF.fromBlob(url)
        : GeoTIFF.fromUrl(url));
      let image_count = await tiff.getImageCount();

      // Image0 is the main image
      let image0 = await tiff.getImage(0);
      let images = [];
      let labelImages = [];
      if ("SubIFDs" in image0.fileDirectory) {
        images = await Promise.all(
          image0.fileDirectory.SubIFDs.map(async (ifd_offset) => {
            let ifd = await tiff.parseFileDirectoryAt(ifd_offset);
            return new GeoTIFF.GeoTIFFImage(
              ifd.fileDirectory,
              ifd.geoKeyDirectory,
              tiff.dataView,
              tiff.littleEndian,
              tiff.cache,
              tiff.source
            );
          })
        );
        images.unshift(image0);
        if (image_count > 1) {
          for (let i = 1; i < image_count; i++) {
            // GeoTIFFImage
            let labelImage = await tiff.getImage(i);
            labelImages.push(labelImage);
          }
        }
      } else {
        images = await Promise.all(
          [...Array(image_count).keys()].map((index) => tiff.getImage(index))
        );
      }

      // Filter out images with photometricInterpretation.TransparencyMask
      images = images.filter(
        (image) =>
          image.fileDirectory.photometricInterpretation !==
          GeoTIFF.globals.photometricInterpretations.TransparencyMask
      );
      // Sort by width (largest first), then detect pyramids
      images.sort((a, b) => b.getWidth() - a.getWidth());
      // find unique aspect ratios (with tolerance to account for rounding)
      const tolerance = 0.015;
      let aspectRatioSets = images.reduce((accumulator, image) => {
        let r = image.getWidth() / image.getHeight();
        let exists = accumulator.filter(
          (set) => Math.abs(1 - set.aspectRatio / r) < tolerance
        );
        if (exists.length == 0) {
          let set = {
            aspectRatio: r,
            images: [image],
          };
          accumulator.push(set);
        } else {
          exists[0].images.push(image);
        }
        return accumulator;
      }, []);

      let imagesets = aspectRatioSets.map((set) => set.images);

      if (imagesets.length > 0) {
        // NOTE: temporarily assume the first imageset to be useful
        let imageset = imagesets[0];
        return {
          GeoTIFF: tiff,
          GeoTIFFImages: imageset,
          LabelImages: labelImages,
        };
      } else {
        console.warn("No imagesets found in TIFF...");
        return undefined;
      }
    },
    from_url: async function (url, min_zoom = -10, uri = null) {
      const { GeoTIFF, GeoTIFFImages } = await GeoRasterLayer.getAllImageSets(
        url,
        {}
      );

      return new GeoRasterLayer(
        GeoTIFF,
        GeoTIFFImages,
        {
          minZoom: min_zoom,
          updateWhenZooming: true,
          debugLevel: 0,
        },
        uri
      );
    },
  },

  /**
   * Initialization helper function.
   */
  _setupLevels: function () {
    if (this._ready) {
      return;
    }

    let images = this.GeoTIFFImages.sort((a, b) => b.getWidth() - a.getWidth());

    //default to 256x256 tiles, but defer to options passed in
    let defaultTileWidth = 256;
    let defaultTileHeight = 256;

    //the first image is the highest-resolution view (at least, with the largest width)
    let fullWidth = (this.width = images[0].getWidth());
    let fullHeight = (this.height = images[0].getHeight());
    this.tileOverlap = 0;
    this.minLevel = 0;
    this.aspectRatio = this.width / this.height;
    this.dimensions = new L.Point(this.width, this.height);

    //a valid tiled pyramid has strictly monotonic size for levels
    let pyramid = images.reduce(
      (acc, im) => {
        if (acc.width !== -1) {
          acc.valid = acc.valid && im.getWidth() < acc.width; //ensure width monotonically decreases
        }
        acc.width = im.getWidth();
        return acc;
      },
      { valid: true, width: -1 }
    );

    if (pyramid.valid) {
      this.levels = images.map((image) => {
        let w = image.getWidth();
        let h = image.getHeight();
        return {
          width: w,
          height: h,
          tileWidth:
            this.options.tileWidth || image.getTileWidth() || defaultTileWidth,
          tileHeight:
            this.options.tileHeight ||
            image.getTileHeight() ||
            defaultTileHeight,
          image: image,
          scalefactor: 1,
        };
      });
      this.maxLevel = this.levels.length - 1;
    } else {
      let numPowersOfTwo = Math.ceil(
        Math.log2(
          Math.max(fullWidth / defaultTileWidth, fullHeight / defaultTileHeight)
        )
      );
      let levelsToUse = [...Array(numPowersOfTwo).keys()].filter(
        (v) => v % 2 == 0
      ); //use every other power of two for scales in the "pyramid"

      this.levels = levelsToUse.map((levelnum) => {
        let scale = Math.pow(2, levelnum);
        let image = images
          .filter((im) => im.getWidth() * scale >= fullWidth)
          .slice(-1)[0]; //smallest image with sufficient resolution
        return {
          width: fullWidth / scale,
          height: fullHeight / scale,
          tileWidth:
            this.options.tileWidth || image.getTileWidth() || defaultTileWidth,
          tileHeight:
            this.options.tileHeight ||
            image.getTileHeight() ||
            defaultTileHeight,
          image: image,
          scalefactor: (scale * image.getWidth()) / fullWidth,
        };
      });
      this.maxLevel = this.levels.length - 1;
    }
    this.levels = this.levels.sort((a, b) => a.width - b.width);

    this._tileWidth = this.levels[0].tileWidth;
    this._tileHeight = this.levels[0].tileHeight;

    this._setupComplete();
  },

  _setupScaling: function () {},

  _setupComplete: function () {
    this._ready = true;
  },

  // ------------

  /**
   * Creates new tile for coordinates and returns finished
   * map canvas tile in the Leaflet DoneCallback. Overrides
   * GridLayer createTile function
   * @function
   *
   */
  createTile: function (coords, done) {
    // console.log("Slide: creating tile...");
    /* This tile is the square piece of the Leaflet map that we draw on */
    const tile = L.DomUtil.create("canvas", "leaflet-tile");

    // we do this because sometimes css normalizers will set * to box-sizing: border-box
    tile.style.boxSizing = "content-box";

    // start tile hidden
    tile.style.visibility = "hidden";

    const context = tile.getContext("2d");

    // note that we aren't setting the tile height or width here
    // drawTile dynamically sets the width and padding based on
    // how much the georaster takes up the tile area
    // this.drawTile({ tile, coords, context, done });
    // TODO: replace white boxes
    // context.fillStyle = "#FFFFFF"; // White color
    // context.fillRect(0, 0, tile.width, tile.height);
    tile.style.visibility = "visible";
    const dataUrl = this._coordToImage(coords, tile, done);
    return tile;
  },

  _rasterToImageData: function (raster, level, canvas = null) {
    let data = new Uint8ClampedArray(raster.data);
    if (canvas === null) {
      canvas = document.createElement("canvas");
    }
    canvas.width = level.tileWidth;
    canvas.height = level.tileHeight;

    let photometricInterpretation =
      level.image.fileDirectory.PhotometricInterpretation;
    let arr;
    switch (photometricInterpretation) {
      case GeoTIFF.globals.photometricInterpretations.WhiteIsZero: // grayscale, white is zero
        arr = Converters.RGBAfromWhiteIsZero(
          data,
          2 ** level.image.fileDirectory.BitsPerSample[0]
        );
        break;
      case GeoTIFF.globals.photometricInterpretations.BlackIsZero: // grayscale, white is zero
        arr = Converters.RGBAfromBlackIsZero(
          data,
          2 ** level.image.fileDirectory.BitsPerSample[0]
        );
        break;
      case GeoTIFF.globals.photometricInterpretations.RGB: // RGB
        arr = Converters.RGBAfromRGB(data);
        break;
      case GeoTIFF.globals.photometricInterpretations.Palette: // colormap
        arr = Converters.RGBAfromPalette(
          data,
          2 ** level.image.fileDirectory.colorMap
        );
        break;
      case GeoTIFF.globals.photometricInterpretations.TransparencyMask: // Transparency Mask
        break;
      case GeoTIFF.globals.photometricInterpretations.CMYK: // CMYK
        arr = Converters.RGBAfromCMYK(data);
        break;
      case GeoTIFF.globals.photometricInterpretations.YCbCr: // YCbCr
        arr = Converters.RGBAfromYCbCr(data);
        break;
      case GeoTIFF.globals.photometricInterpretations.CIELab: // CIELab
        arr = Converters.RGBAfromCIELab(data);
        break;
    }

    // Should stitch together data into one canvas
    // Note: Draws image on canvas
    const imageData = new ImageData(arr, canvas.width, canvas.height);
    return imageData;
  },

  /**
   * Retreives the level index and level_mpp
   * of the level less than or equal
   * to provided mpp (or lowest level)
   */
  getClosestLevel: function (mpp) {
    if (mpp === null) {
      mpp = this._mpp;
    }
    this.levels.forEach((level, index) => {
      level.index = index;
    });
    const levels = this.levels.slice().sort((a, b) => b.height - a.height);
    // Get level less than or equal to mpp or min
    let level = levels[0];
    let level_mpp;
    for (let curr_level of levels) {
      level_mpp = this.micronHeight / curr_level.height;
      if (level_mpp <= mpp) level = curr_level;
      else break;
    }

    return { level_idx: level.index, level_mpp, tile_size: level.tileHeight };
  },

  /**
   * Retreives the tile at the level less than or equal
   * to mpp (or lowest level) as an ImageData type.
   * @function
   * @param {Object} bbox x,y,w,h, assume x, y are indices in the level
   *  grid, and w, h are also in terms of the level grid indices
   *  (i.e. bbox:)
   * @returns {ImageData}
   */
  getImageData: async function (bbox, level_idx) {
    const level = this.levels[level_idx];
    const { x, y, w, h } = bbox;

    let abortController = new AbortController();
    const abortSignal = abortController.signal;

    const finalWidth = w * level.tileWidth;
    const finalHeight = h * level.tileHeight;

    // Create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = finalWidth;
    canvas.height = finalHeight;
    const ctx = canvas.getContext("2d");

    for (let i = 0; i < w; i++) {
      for (let j = 0; j < h; j++) {
        const raster = await level.image.getTileOrStrip(
          x + i,
          y + j,
          null,
          this._pool,
          abortSignal
        );
        const imageData = this._rasterToImageData(raster, level);

        let img_x = i * level.tileWidth;
        let img_y = j * level.tileHeight;
        ctx.putImageData(imageData, img_x, img_y);
      }
    }

    // Get the final ImageData from the canvas
    return ctx.getImageData(0, 0, finalWidth, finalHeight);
  },

  // Private Functions
  _coordToImage: function (coords, canvas, done) {
    const { x, y, z } = coords;
    // Find proper coords

    // Subtract minNativeZoom from z to ensure we index into the first level, otherwise z may be negative
    const levelnum = z - this._minNativeZoom; // (i.e. no Array out of bounds issues)
    // TODO: Determine x, y cutoff points

    const level = this.levels[levelnum];
    // console.log({coords})
    // let startTime = this.options.logLatency && Date.now();

    // let abortController = src.abortController = new AbortController(); // add abortController to the src object so OpenSeadragon can abort the request
    // let abortSignal = abortController.signal;
    let abortController = new AbortController();
    const abortSignal = abortController.signal;

    // Use getTileOrStrip followed by converters because it is noticably more efficient than readRGB
    // level.image is

    const levelImage = level.image;

    const numTilesPerRow = Math.ceil(
      levelImage.getWidth() / levelImage.getTileWidth()
    );
    const numTilesPerCol = Math.ceil(
      levelImage.getHeight() / levelImage.getTileHeight()
    );

    return levelImage
      .getTileOrStrip(
        x,
        y,
        null,
        this._pool,
        abortSignal // TODO: re-add abort signal
      )
      .then((raster) => {
        let data = new Uint8ClampedArray(raster.data);
        canvas.width = level.tileWidth;
        canvas.height = level.tileHeight;
        let ctx = canvas.getContext("2d");

        let photometricInterpretation =
          level.image.fileDirectory.PhotometricInterpretation;
        let arr;
        switch (photometricInterpretation) {
          case GeoTIFF.globals.photometricInterpretations.WhiteIsZero: // grayscale, white is zero
            arr = Converters.RGBAfromWhiteIsZero(
              data,
              2 ** level.image.fileDirectory.BitsPerSample[0]
            );
            break;
          case GeoTIFF.globals.photometricInterpretations.BlackIsZero: // grayscale, white is zero
            arr = Converters.RGBAfromBlackIsZero(
              data,
              2 ** level.image.fileDirectory.BitsPerSample[0]
            );
            break;
          case GeoTIFF.globals.photometricInterpretations.RGB: // RGB
            arr = Converters.RGBAfromRGB(data);
            break;
          case GeoTIFF.globals.photometricInterpretations.Palette: // colormap
            arr = Converters.RGBAfromPalette(
              data,
              2 ** level.image.fileDirectory.colorMap
            );
            break;
          case GeoTIFF.globals.photometricInterpretations.TransparencyMask: // Transparency Mask
            break;
          case GeoTIFF.globals.photometricInterpretations.CMYK: // CMYK
            arr = Converters.RGBAfromCMYK(data);
            break;
          case GeoTIFF.globals.photometricInterpretations.YCbCr: // YCbCr
            arr = Converters.RGBAfromYCbCr(data);
            break;
          case GeoTIFF.globals.photometricInterpretations.CIELab: // CIELab
            arr = Converters.RGBAfromCIELab(data);
            break;
        }
        // Note: Draws image on canvas
        const imageData = new ImageData(arr, canvas.width, canvas.height);

        // arguments: x, y, dirtyX, dirtyY, dirtyWidth, dirtyHeight
        // dirtyWidth and dirtyHeight specify which parts of the array to plot
        let dirtyWidth = level.tileWidth;
        let dirtyHeight = level.tileHeight;

        // If tile is at the boundary
        if (x === numTilesPerRow - 1) {
          const removeWidth =
            numTilesPerRow * levelImage.getTileWidth() - levelImage.getWidth();
          dirtyWidth -= removeWidth;
        }
        if (y === numTilesPerCol - 1) {
          const removeHeight =
            numTilesPerCol * levelImage.getTileHeight() -
            levelImage.getHeight();
          dirtyHeight -= removeHeight;
        }

        if (this.debugLevel >= 2)
          console.log({
            removeHeight,
            levelImage,
            tileWidth: levelImage.getTileWidth(),
            tileHeight: levelImage.getTileHeight(),
            "levelImage.getWidth()": levelImage.getWidth(),
          });
        if (this.debugLevel >= 2)
          console.log({ x, y, numTilesPerRow, numTilesPerCol });
        if (this.debugLevel >= 2) console.log({ dirtyWidth, dirtyHeight });
        ctx.putImageData(imageData, 0, 0, 0, 0, dirtyWidth, dirtyHeight);
        let dataURL = canvas.toDataURL("image/jpeg", 0.8);

        // Send tile to Leaflet `done` callback to indicate tile has been drawn
        done(null, canvas);
        return dataURL;
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  },

  // Overriden Methods
  getBounds: function () {
    // this.initBounds();
    // return this._bounds;
    return this.bounds;
  },

  initBounds: function (options) {
    if (!options) options = this.options;
    if (!this._bounds) {
    }
  },
});

// Private Functions
/**
 * Extracts MPP from Aperio svs string. Expects ImageDescription
 * to have a substring of form `MPP = <mpp>`.
 * @returns mpp: float, returns if present in string, otherwise
 *  returns undefined.
 */
function _extractMPP() {
  /**
   *  Given file directory, if XResolution and YResolution,
   *    compute mpp assuming the resolution is relative to
   *    centimeters (cm).
   * */
  const parseFromXResolution = (fd) => {
    const cm_const = 10000; // Assumes resolution in pixels per cm
    let xmpp = cm_const / (fd.XResolution[0] / fd.XResolution[1]);
    let ympp = cm_const / (fd.YResolution[0] / fd.YResolution[1]);
    if (fd.ResolutionUnit == 2) {
      xmpp *= INCH2CM;
      ympp *= INCH2CM;
    }

    if (xmpp !== ympp) {
      console.warn(
        `[L.georaster] xmpp: ${xmpp} and ympp: ${ympp} are not equal. Note that viewer is assuming xmpp and ympp to be equal. xmpp will be used for both x and y directions.`
      );
    }

    return xmpp; // Adopts XResolution
  };

  const fd = this.GeoTIFFImages[0].fileDirectory;
  if (fd.XResolution && fd.YResolution) {
    console.log(
      "[L.georaster] Slide is of proper format and has resolution metadata.",
      fd
    );
    const mpp = parseFromXResolution(fd);
    return mpp;
  }

  // Else, assume svs file and extract from Image Description
  // Extract Image Description
  const imageDescription = fd.ImageDescription;
  // Regular expression pattern to match the MPP value
  var pattern = /MPP\s*=\s*([\d.]+)/;

  // Match the pattern against the input string
  var match = imageDescription.match(pattern);

  // Extract the MPP value from the match
  if (match && match[1]) {
    var mpp = parseFloat(match[1]);
    return mpp;
  }

  // Return null if no match is found
  return undefined;
}

// Adapted from https://github.com/geotiffjs/geotiff.js
class Converters {
  static RGBAfromYCbCr(input) {
    const rgbaRaster = new Uint8ClampedArray((input.length * 4) / 3);
    let i, j;
    for (i = 0, j = 0; i < input.length; i += 3, j += 4) {
      const y = input[i];
      const cb = input[i + 1];
      const cr = input[i + 2];

      rgbaRaster[j] = y + 1.402 * (cr - 0x80);
      rgbaRaster[j + 1] = y - 0.34414 * (cb - 0x80) - 0.71414 * (cr - 0x80);
      rgbaRaster[j + 2] = y + 1.772 * (cb - 0x80);
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }
  static RGBAfromRGB(input) {
    const rgbaRaster = new Uint8ClampedArray((input.length * 4) / 3);
    let i, j;
    for (i = 0, j = 0; i < input.length; i += 3, j += 4) {
      rgbaRaster[j] = input[i];
      rgbaRaster[j + 1] = input[i + 1];
      rgbaRaster[j + 2] = input[i + 2];
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }

  static RGBAfromWhiteIsZero(input, max) {
    const rgbaRaster = new Uint8Array(input.length * 4);
    let value;
    for (let i = 0, j = 0; i < input.length; ++i, j += 3) {
      value = 256 - (input[i] / max) * 256;
      rgbaRaster[j] = value;
      rgbaRaster[j + 1] = value;
      rgbaRaster[j + 2] = value;
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }

  static RGBAfromBlackIsZero(input, max) {
    const rgbaRaster = new Uint8Array(input.length * 4);
    let value;
    for (let i = 0, j = 0; i < input.length; ++i, j += 3) {
      value = (input[i] / max) * 256;
      rgbaRaster[j] = value;
      rgbaRaster[j + 1] = value;
      rgbaRaster[j + 2] = value;
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }

  static RGBAfromPalette(input, colorMap) {
    const rgbaRaster = new Uint8Array(input.length * 4);
    const greenOffset = colorMap.length / 3;
    const blueOffset = (colorMap.length / 3) * 2;
    for (let i = 0, j = 0; i < input.length; ++i, j += 3) {
      const mapIndex = input[i];
      rgbaRaster[j] = (colorMap[mapIndex] / 65536) * 256;
      rgbaRaster[j + 1] = (colorMap[mapIndex + greenOffset] / 65536) * 256;
      rgbaRaster[j + 2] = (colorMap[mapIndex + blueOffset] / 65536) * 256;
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }

  static RGBAfromCMYK(input) {
    const rgbaRaster = new Uint8Array(input.length);
    for (let i = 0, j = 0; i < input.length; i += 4, j += 4) {
      const c = input[i];
      const m = input[i + 1];
      const y = input[i + 2];
      const k = input[i + 3];

      rgbaRaster[j] = 255 * ((255 - c) / 256) * ((255 - k) / 256);
      rgbaRaster[j + 1] = 255 * ((255 - m) / 256) * ((255 - k) / 256);
      rgbaRaster[j + 2] = 255 * ((255 - y) / 256) * ((255 - k) / 256);
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }

  static RGBAfromCIELab(input) {
    // from https://github.com/antimatter15/rgb-lab/blob/master/color.js
    const Xn = 0.95047;
    const Yn = 1.0;
    const Zn = 1.08883;
    const rgbaRaster = new Uint8Array((input.length * 4) / 3);

    for (let i = 0, j = 0; i < input.length; i += 3, j += 4) {
      const L = input[i + 0];
      const a_ = (input[i + 1] << 24) >> 24; // conversion from uint8 to int8
      const b_ = (input[i + 2] << 24) >> 24; // same

      let y = (L + 16) / 116;
      let x = a_ / 500 + y;
      let z = y - b_ / 200;
      let r;
      let g;
      let b;

      x = Xn * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
      y = Yn * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
      z = Zn * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

      r = x * 3.2406 + y * -1.5372 + z * -0.4986;
      g = x * -0.9689 + y * 1.8758 + z * 0.0415;
      b = x * 0.0557 + y * -0.204 + z * 1.057;

      r = r > 0.0031308 ? 1.055 * r ** (1 / 2.4) - 0.055 : 12.92 * r;
      g = g > 0.0031308 ? 1.055 * g ** (1 / 2.4) - 0.055 : 12.92 * g;
      b = b > 0.0031308 ? 1.055 * b ** (1 / 2.4) - 0.055 : 12.92 * b;

      rgbaRaster[j] = Math.max(0, Math.min(1, r)) * 255;
      rgbaRaster[j + 1] = Math.max(0, Math.min(1, g)) * 255;
      rgbaRaster[j + 2] = Math.max(0, Math.min(1, b)) * 255;
      rgbaRaster[j + 3] = 255;
    }
    return rgbaRaster;
  }
}

export { GeoRasterLayer };
