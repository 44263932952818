// Function to update cursor style
function updateCursorStyle(map, isCommandKeyDown) {
  if (isCommandKeyDown) {
    map.getContainer().style.cursor = "crosshair";
  } else {
    map.getContainer().style.cursor = "";
  }
}

/**
 * Set cursor to crosshair when command (MacOS) or ctrl (Windows)
 * is held down. Used to pinpoint location that will be
 * copied.
 * @param {L.Map} map Leaflet map
 */
export function setCrosshairOnCommand(map) {
  /**  Crosshair event handlers */
  let isCommandKeyDown = false; // Flag to track Command key state

  // Event listeners for keydown and keyup events
  window.addEventListener("keydown", function (event) {
    if (event.key === "Meta" || event.key === "Control") {
      isCommandKeyDown = true;
      updateCursorStyle(map, isCommandKeyDown);
    }
  });

  window.addEventListener("keyup", function (event) {
    if (event.key === "Meta" || event.key === "Control") {
      isCommandKeyDown = false;
      updateCursorStyle(map, isCommandKeyDown);
    }
  });

  updateCursorStyle();
}

export function displayCursorLocation(map) {
  const cursorLocationControl = L.control();

  cursorLocationControl.onAdd = function (map) {
    this._div = L.DomUtil.create("div", "cursor-location-control");
    this._div.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    this._div.style.color = "white";
    this._div.style.padding = "8px";
    this.update();
    return this._div;
  };

  cursorLocationControl.update = function (latlng) {
    this._div.innerHTML = latlng
      ? `${latlng.lng.toFixed(1)}μ, ${latlng.lat.toFixed(1)}μ`
      : "";
  };

  cursorLocationControl.addTo(map);

  map.on("mousemove", function (e) {
    cursorLocationControl.update(e.latlng);
  });
}

export function displayMapZoom(map) {
  const zoomControl = L.control();

  zoomControl.onAdd = function (map) {
    this._div = L.DomUtil.create("div", "zoom-control");
    this._div.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    this._div.style.color = "white";
    this._div.style.padding = "8px";
    this.update();
    return this._div;
  };

  zoomControl.update = function (mpp) {
    this._div.innerHTML = mpp ? `${mpp.toFixed(2)} mpp` : "";
  };

  zoomControl.addTo(map);

  map.on("zoom", function (e) {
    const zoom = map.getZoom();
    const mpp = Math.pow(2, -zoom);
    zoomControl.update(mpp);
  });
}

export function addClickCopyHandler(map, showAlert) {
  const handleLocationCopyClick = (e) => {
    const originalEvent = e.originalEvent;

    if (originalEvent.metaKey || originalEvent.ctrlKey) {
      const url = new URL(window.location);
      let searchParams = new URLSearchParams(url.search);
      searchParams.set("x", e.latlng.lng.toFixed(1));
      searchParams.set("y", e.latlng.lat.toFixed(1));

      url.search = searchParams.toString().replace(/%2F/g, '/').replace(/%3A/g, ':');
      const copyUrl = url.toString();
      navigator.clipboard
        .writeText(copyUrl)
        .then(() => {
          showAlert(
            `Successfully copied location url to clipboard: ${copyUrl}`,
            `success`
          );
        })
        .catch((err) => {
          showAlert(
            `Error in copying location url to clipboard. Copy the link manually here: ${copyUrl}`,
            `error`
          );
        });
    }
  };

  map.on("click", handleLocationCopyClick);
}
