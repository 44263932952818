/**
 * Adapted from:
 * https://dev.luciad.com/portal/productDocumentation/LuciadRIA/docs/articles/howto/geotiff/loading_geotiff_images.html
 */
export const createCanvasFromRaster = (raster) => {
  let width, height;
  const canvas = document.createElement("canvas");
  canvas.width = width = raster.width;
  canvas.height = height = raster.height;
  const ctx = canvas.getContext("2d");
  const imageData = ctx.createImageData(raster.width, raster.height);

  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      const srcIdx = 3 * i * width + 3 * j;
      const idx = 4 * i * width + 4 * j;
      imageData.data[idx] = raster[srcIdx];
      imageData.data[idx + 1] = raster[srcIdx + 1];
      imageData.data[idx + 2] = raster[srcIdx + 2];
      imageData.data[idx + 3] = 255; // fully opaque
    }
  }

  ctx.putImageData(imageData, 0, 0);
  return canvas;
};

export const fetchAndDisplayImage = async (labelImage) => {
  const raster = await labelImage.readRGB();
  const canvas = createCanvasFromRaster(raster);
  const imageSrc = canvas.toDataURL("image/png");
  return { raster, imageSrc };
};
