import AWS from "aws-sdk";
import { DEFAULT_S3_REGION } from "../constants";

let S3 = Object.fromEntries(
  ["us-west-1", "us-east-2"].map((region) => {
    return [region, null];
  })
);

export function getHostCwd(url) {
  const httpRegex =
    /\b(?:https?|ftp):\/\/(?:localhost|\d{1,3}(?:\.\d{1,3}){3})(?::\d+)?\b/;
  const s3Regex = /s3:\/\/[^\/]+/;
  const regex = url.includes("s3:") ? s3Regex : httpRegex;
  return [url.match(regex)[0], url.replace(regex, "")];
}

export function getUrl(layers, params) {
  let elements = [];
  for (const [k, v] of Object.entries(params)) {
    if (v) elements.push(`${k}=${v}`);
  }
  for (const layer of layers) {
    elements.push(
      `uri=${layer.uri}` +
        (layer.region !== DEFAULT_S3_REGION ? `&region=${layer.region}` : "")
    );
  }
  return elements.length > 0 ? `${elements.join("&")}` : "";
}

export async function ls(url) {
  let contents = [];
  if (url.includes("s3://")) {
    const hostCwd = getHostCwd(url);
    const bucket = hostCwd[0].substring(5);
    const prefix = hostCwd[1].substring(1);
    const params = {
      Bucket: bucket,
      Prefix: prefix,
      Delimiter: "/",
    };
    let all_files = [];
    let all_folders = [];
    if (!S3[DEFAULT_S3_REGION]) {
      S3[DEFAULT_S3_REGION] = new AWS.S3({ region: DEFAULT_S3_REGION });
    }
    do {
      const data = await S3[DEFAULT_S3_REGION].listObjectsV2(params).promise();
      const files = data.Contents.map((obj) => obj.Key.replace(prefix, ""));
      const folders = data.CommonPrefixes.map((obj) =>
        obj.Prefix.replace(prefix, "")
      );
      all_files.push(...files);
      all_folders.push(...folders);
      params.ContinuationToken = data.NextContinuationToken;
    } while (params.ContinuationToken);
    contents = [
      ...all_files.map((file) => ({
        name: file,
        isFolder: false,
        ext: file.split(".").at(-1),
      })),
      ...all_folders.map((folder) => {
        return {
          name: folder,
          isFolder: true,
          ext: null,
        };
      }),
    ];
  } else {
    const response = await fetch(url);
    const str = await response.text();
    let el = document.createElement("html");
    el.innerHTML = str;
    const list = el.getElementsByTagName("a");
    let name = null;
    let isFolder = null;
    for (let i = 0; i < list.length; i++) {
      name = list[i].innerHTML;
      isFolder = name.slice(-1) === "/" && !name.includes("lngj/");
      contents[i] = {
        name: name,
        isFolder: isFolder,
        ext: isFolder ? null : name.split(".").at(-1),
      };
    }
    contents.shift();
  }
  return contents;
}

export function getBucketKeyFromS3Uri(s3Uri) {
  const regex = /^s3:\/\/([^/]+)\/(.*)$/;
  const matches = s3Uri.match(regex);
  if (matches) {
    const bucket = matches[1];
    const key = matches[2];
    return { bucket, key };
  } else {
    throw new Error("Invalid S3 URI format");
  }
}

export const getFilenameFromUri = (uri) => {
  // Extract the filename from the S3 URI
  const filenameWithExtension = uri.substring(uri.lastIndexOf("/") + 1);

  // Remove the extension from the filename
  const filenameWithoutExtension = filenameWithExtension
    .split(".")
    .slice(0, -1)
    .join(".");

  return filenameWithoutExtension;
};

export const getSignedUrlFromUri = async (
  uri,
  region,
  operation = "getObject",
  contentType = undefined,
  expires = 36000
) => {
  try {
    const { bucket, key } = getBucketKeyFromS3Uri(uri);
    if (Array.isArray(operation)) {
      return Promise.all(
        operation.map((op) => getSignedUrlFromUri(uri, region, op, expires))
      );
    }
    if (!S3[region]) {
      S3[region] = new AWS.S3({ region: region });
    }
    if (operation !== "putObject") {
      return S3[region].getSignedUrlPromise(operation, {
        Bucket: bucket,
        Key: key,
        Expires: expires,
      });
    } else {
      return S3[region].getSignedUrlPromise(operation, {
        Bucket: bucket,
        Key: key,
        Expires: expires,
        ContentType: contentType,
      });
    }
  } catch (error) {
    console.error(
      `[aws:getSignedUrl] Error getting signed url for: uri=${uri}, 
      operation=${operation}, region=${region}`,
      error
    );
  }
};

export function updateAWSCredentials(idToken) {
  AWS.config.update({
    region: "us-east-2",
  });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(
    {
      IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      Logins: {
        [`cognito-idp.us-east-2.amazonaws.com/${process.env.REACT_APP_AWS_USER_POOLS_ID}`]:
          idToken,
      },
    },
    {
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
    }
  );
}
