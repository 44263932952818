import React, { useState } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "./pages/main";
import { ViewerPage, ViewerPageLoader } from "./pages/viewer";
import { AlertProvider } from "./components/AlertProvider";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css"; // default theme

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
  });

  const router = createBrowserRouter([
    {
      children: [
        {
          path: "/",
          element: (
            <Authenticator.Provider>
              <AlertProvider>
                <ThemeProvider theme={theme}>
                  <Main />
                </ThemeProvider>
              </AlertProvider>
            </Authenticator.Provider>
          ),
          children: [
            {
              path: "viewer/",
              element: <ViewerPage />,
              loader: ViewerPageLoader,
              children: [],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
