import { useEffect } from "react";

const Handlers = ({ editLayerObj }) => {
  useEffect(() => {
    const ann_ctrl = editLayerObj;

    function handleKeyDown(event) {
      const map = editLayerObj?._map;
      if (ann_ctrl._map) {
        if (event.ctrlKey || event.metaKey) {
          switch (event.key) {
            case "s": // Save
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("saving");
              event.preventDefault();
              break;
            case "m": // Merge
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.merge_feature();
              break;
            case "l": // Split
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.split_feature();
              event.preventDefault();
              break;
            case "e": // Draw Exclusive
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("insert_draw_exclusive");
              break;
            case "i": // Visual Freeform
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("insert_box");
              break;
            case "n": // Insert Draw
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("insert_draw");
              break;
            case "p": // Insert Point
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("insert_point");
              break;
            case "d": // Insert Erase
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("insert_erase");
              break;
            case "b": // Visual Freeform
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("visual_freeform");
              break;
            case "h": // Visual Block
              if (ann_ctrl._tile_buffer.tile.id !== null)
                ann_ctrl.update_state("visual_block");
              break;
            case "x": // Normal
              event.preventDefault();
              ann_ctrl.update_state("normal");
              break;
            case "a": // Select Tile
              event.preventDefault();
              ann_ctrl.update_state("select_tile");
              break;
            case "v": // Toggle Layers
              event.preventDefault();
              for (let layerType in ann_ctrl._tile_buffer.layers) {
                if (ann_ctrl._tile_buffer.layers[layerType]) {
                  if (ann_ctrl._tile_buffer.layers[layerType]._map === null) {
                    ann_ctrl._tile_buffer.layers[layerType].addTo(map);
                  } else {
                    ann_ctrl._tile_buffer.layers[layerType].remove();
                  }
                }
              }
              break;
            case ",": // Segment
              event.preventDefault();
              ann_ctrl.update_state("insert_seg_outline");
              break;
            case ";": // SAM
              // TODO: set into embedding mode
              ann_ctrl.model_embed();
              break;
            case "z": // Undo
              event.preventDefault();
              // TODO : Cache prior boxes
              break;

            default:
              break;
          }
        } else {
          // No Ctrl+
          switch (event.key) {
            case "Escape": // Normal
              ann_ctrl.update_state("normal");
              break;
            case "Delete": // Delete Object
            case "Backspace":
              ann_ctrl._tile_buffer.clear("selected");
              break;
            default:
              break;
          }
        }
      }
    }

    if (ann_ctrl) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (ann_ctrl) {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [editLayerObj]);
};

export default Handlers;
