import { Box, Tooltip } from "@mui/material";
import { Grid, IconButton } from "@mui/material";

import OpenWithOutlinedIcon from "@mui/icons-material/OpenWithOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import CallMergeOutlinedIcon from "@mui/icons-material/CallMergeOutlined";
import PictureInPictureOutlinedIcon from "@mui/icons-material/PictureInPictureOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

const EDIT_PANEL = [
  { state: "normal", icon: <OpenWithOutlinedIcon /> },
  { state: "visual_click", icon: <ControlPointOutlinedIcon /> },
  { state: "visual_block", icon: <AspectRatioOutlinedIcon /> },
  { state: "visual_freeform", icon: <AdjustOutlinedIcon /> },
  { state: "insert_seg_outline", icon: <PictureInPictureOutlinedIcon /> },
  { state: "insert_box", icon: <SquareOutlinedIcon /> },
  { state: "insert_point", icon: <FiberManualRecordOutlinedIcon /> },
  { state: "insert_draw", icon: <BrushOutlinedIcon /> },
  { state: "insert_draw_exclusive", icon: <EditOutlinedIcon /> },
  { state: "insert_erase", icon: <DeleteOutlinedIcon /> },
  { state: "saving", icon: <SaveAsOutlinedIcon /> },
  { state: "select_tile", icon: <AddBoxOutlinedIcon /> },
  { state: "split_feature", icon: <CallSplitOutlinedIcon /> },
  { state: "merge_feature", icon: <CallMergeOutlinedIcon /> },
];

export const EditPanel = ({ editLayerObj }) => {
  const handleClick = async (state) => {
    try {
      switch (state) {
        case "split_feature": {
          console.log("splitting");
          editLayerObj.split_feature();
          break;
        }
        case "merge_feature": {
          console.log("merging");
          editLayerObj.merge_feature();
          break;
        }
        default:
          editLayerObj.update_state(state);
          break;
      }
    } catch (error) {
      console.log(`[Editor] Error updating state ${state}`, error);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        right: 10,
        top: 140,
        zIndex: 1000,
        backgroundColor: "rgba(200, 200, 200, 0.6)",
        display: "flex",
        borderRadius: 2,
      }}
    >
      {editLayerObj ? (
        <Grid container direction="column" spacing={1}>
          {EDIT_PANEL.map(({ state, icon }, index) => (
            <Grid item key={index}>
              <Tooltip title={`${state}`} placement="left-start">
                <IconButton
                  onClick={() => handleClick(state)}
                  onKeyUp={(event) => {
                    switch (event.key) {
                      case "Escape": {
                        handleClick("normal");
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }}
                >
                  {icon}
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Box>
  );
};
