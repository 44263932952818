import { useState } from "react";
import { Box, IconButton, Grow } from "@mui/material";

import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

const CollapsablePanel = ({
  children,
  left,
  right,
  top,
  bottom,
  width,
  height,
  closedIcon,
  opened,
  setOpened,
  minimizeIcon,
  childrenPosition = "right",
  sx = {},
  openedStyle = {},
}) => {
  const [internalOpened, setInternalOpened] = useState(false);
  if (!opened && !setOpened) {
    opened = internalOpened;
    setOpened = setInternalOpened;
  }

  const handleOpenClick = () => {
    setOpened(true);
  };

  const handleCloseClick = () => {
    setOpened(false);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: left ?? null,
        right: right ?? null,
        top: top ?? null,
        bottom: bottom ?? null,
        zIndex: 1000,
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        display: "flex",
        justifyContent: "center",
        width: opened ? width : 50,
        height: opened ? height : 50,
        borderRadius: 2,
        // border: "1px solid #000",
        boxShadow: 4,
        transition: "width 0.2s, height 0.2s, top 0.2s",
        ...sx,
      }}
    >
      {!opened ? (
        <IconButton onClick={handleOpenClick}>{closedIcon}</IconButton>
      ) : (
        <>
          <Grow
            in={opened}
            style={{ transformOrigin: "0 0 0", ...openedStyle }}
            {...(opened ? { timeout: 500 } : {})}
          >
            <div>
              {childrenPosition === "left" && children}
              <IconButton
                onClick={handleCloseClick}
                sx={{ float: "right", mt: minimizeIcon ? 1 : 0 }}
              >
                {minimizeIcon ?? <CloseFullscreenOutlinedIcon />}
              </IconButton>
              {childrenPosition === "right" && children}
            </div>
          </Grow>
        </>
      )}
    </Box>
  );
};

export default CollapsablePanel;
