import { unzip, setOptions } from "unzipit";
import { fetch_range, strip_prefix } from "./util";

import type { Reader, ZipInfo } from "unzipit";
import type { AbsolutePath, Async, Readable } from "../types";

setOptions({ workerURL: "unzipit/dist/unzipit-worker.module.js" });

function getTimeElapsed(initTime: number) {
  return Math.floor((Date.now() - initTime) / 1000);
}

export class BlobReader implements Reader {
  constructor(public blob: Blob) {}
  async getLength() {
    return this.blob.size;
  }
  async read(offset: number, length: number) {
    const blob = this.blob.slice(offset, offset + length);
    return new Uint8Array(await blob.arrayBuffer());
  }
}

export class HTTPRangeReader implements Reader {
  private length?: number;
  constructor(public url: string | URL, public headUrl: string | URL) {}

  async getLength() {
    if (this.length === undefined) {
      const req = await fetch(this.headUrl as string, { method: "HEAD" });
      if (!req.ok) {
        throw new Error(
          `failed http request ${this.headUrl}, status: ${req.status}: ${req.statusText}`
        );
      }
      this.length = parseInt(req.headers.get("content-length")!);
      if (Number.isNaN(this.length)) {
        throw Error("could not get length");
      }
    }
    return this.length;
  }

  async read(offset: number, size: number) {
    let _initTime;

    if (size === 0) {
      return new Uint8Array(0);
    }
    _initTime = Date.now();
    const req = await fetch_range({ url: this.url, offset, size });
    if (!req.ok) {
      throw new Error(
        `failed http request ${this.url}, status: ${req.status} offset: ${offset} size: ${size}: ${req.statusText}`
      );
    }
    // console.log(
    //   `[zip.ts | read] fetch_range made in ${getTimeElapsed(_initTime)} s`,
    //   { req, url: this.url, offset, size }
    // );
    // if (size === 30) {
    //   console.trace();
    // }

    _initTime = Date.now();
    const arrBuffer = await req.arrayBuffer();
    // console.log(
    //   `[zip.ts | read] Request into arrayBuffer in ${getTimeElapsed(
    //     _initTime
    //   )} s`,
    //   { arrBuffer }
    // );

    _initTime = Date.now();
    const res = new Uint8Array(arrBuffer);
    // console.log(
    //   `[zip.ts | read] Convert to Uint8Array in ${getTimeElapsed(_initTime)} s`,
    //   { res }
    // );
    return res;
  }
}

/** @experimental */
export class ZipFileStore<R extends Reader> implements Async<Readable> {
  private info: Promise<ZipInfo>;
  constructor(reader: R) {
    this.info = unzip(reader);
  }

  async get(key: AbsolutePath) {
    let _initTime;
    _initTime = Date.now();
    let info = await this.info;
    // console.log(
    //   `[zip.ts] Info promise loaded in ${getTimeElapsed(_initTime)} s`,
    //   { key, info }
    // );

    _initTime = Date.now();
    let entry = info.entries[strip_prefix(key)];
    // console.log(
    //   `[zip.ts] Info entries prefixes stripped in ${getTimeElapsed(
    //     _initTime
    //   )} s`,
    //   { entry }
    // );
    if (!entry) return;

    _initTime = Date.now();
    let arrayBuffer = await entry.arrayBuffer();
    // console.log(
    //   `[zip.ts] Entry arrayBuffer loaded ${getTimeElapsed(_initTime)} s`,
    //   { arrayBuffer }
    // );

    _initTime = Date.now();
    let uintarr = new Uint8Array(arrayBuffer);
    // console.log(
    //   `[zip.ts] Array buffer converted into Uint8Array in ${getTimeElapsed(
    //     _initTime
    //   )} s`
    // );

    return uintarr;
  }

  async has(key: AbsolutePath) {
    return strip_prefix(key) in (await this.info).entries;
  }

  static fromUrl(url: string | URL, headUrl: string | URL) {
    return new ZipFileStore(new HTTPRangeReader(url, headUrl));
  }

  static fromBlob(blob: Blob) {
    return new ZipFileStore(new BlobReader(blob));
  }
}
